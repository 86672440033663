import React, { Component } from "react";
import { connectStoreon } from "storeon/react";
import WithRouter from "../../../HOC/WithRouter";
import { myRequest } from "../../../images";
import UnitSpareRequest from "./UnitSpareRequest";
import {
  LASTURL,
  MAKE_REQUEST_MENU,
  MAKE_REQUEST_OWN_REQUEST,
  MAKE_REQUEST_UNIT,
} from "../../../helpers/config";
import { ACTION_SET_BUTTON_HEADER_ACTION } from "../../../store/helpers/helpers-store";
import { ACTION_SET_VALUE_UNIT_SPARE, DELETE_AUTOPART_FROM_LIST_UNIT_SPARE, GET_LIST_OPTIONS_BY_TYPE, RESET_VALUE_UNIT_SPARE } from "../../../store/requests/requests";
import { setLocaleStore } from "../../../helpers/utils";

class UnitRequestComponent extends Component {
  state = {
    type: "unit",
    isAddToListSparePart: false,
    isAddInfo: false,
  };

  componentDidMount() {
    setTimeout(() =>
          this.props.dispatch(GET_LIST_OPTIONS_BY_TYPE, {
            type: "year",
            screen: this.state.type,
          })
          ,150);
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Запрос на агрегат",
      pathBackButton: () =>
        this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: MAKE_REQUEST_OWN_REQUEST }),
          iconLeft: myRequest,
          styleIconsLeft: { height: 12 },
          title: "Мои запросы",
          className: "button__controll--roze",
        },
      ],
    });
    if(this.props.isDesktop){
      setTimeout(() => 
        this.props.navigate(MAKE_REQUEST_UNIT, { replace: true })
        , 120
      )
    }
  }

  componentWillUnmount(){
    if(this.props.isDesktop){
      setTimeout(() => {
        this.props.navigate(MAKE_REQUEST_MENU, { replace: true })
        this.callback()
      }
        , 50
      )
    }
  }

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  saveValueStore = (obj) =>
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      ...obj,
      screen: this.state.type,
      callback: this.callback,
    });

  handleGetDataOption = (obj) =>
    this.props.dispatch(GET_LIST_OPTIONS_BY_TYPE, {
      ...obj,
      screen: this.state.type,
    });

  callback = () => {
    console.log(this.props.handlerChangeItemMenu)
    this.props?.handlerChangeItemMenu &&
      this.props.handlerChangeItemMenu(99999);
  };

  handleDeleteAutoPartFromList = (id) =>{
    this.props.dispatch(DELETE_AUTOPART_FROM_LIST_UNIT_SPARE, { 
      id, 
      screen: this.state.type,
      type: this.state.type 
    });
}
  handleCompectation = () => {
    this.setState((state) => ({
      ...state,
    }));
  };
  handleSparePart = () => {
    this.setState((state) => ({
      ...state,
      isAddToListSparePart: !state.isAddToListSparePart,
    }));
  };
 
  handleAddInfo = () => {
    this.setState((state) => ({
      ...state,
      isAddInfo: !state.isAddInfo,
    }));
  };

  render() {
    return (
      <UnitSpareRequest
        initValues={this.props.valuesUnitSpare}
        isAddInfo={this.props.isDesktop ?? this.state.isAddInfo}
        handleAddInfo={this.handleAddInfo}
        dispatch={this.props.dispatch}
        isDesktop={this.props.isDesktop}
        saveValueStore={this.saveValueStore}
        valuesUnitSpare={this.props.valuesUnitSpare}
        isAddToListSparePart={this.state.isAddToListSparePart}
        handleSparePart={this.handleSparePart}
        handleCompectation={this.handleCompectation}
        handleGetDataOption={this.handleGetDataOption}
        handleDeleteAutoPartFromList={this.handleDeleteAutoPartFromList}
      />
    );
  }
}
export default connectStoreon(
  "listPrepare",
  "valuesUnitSpare",
  "initValuesUnitSpare",
  WithRouter(UnitRequestComponent)
);
