import { Form, Formik } from "formik";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import { ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import React, { useEffect } from "react";
import CardApplicationContainer from "../../../View/Cards/Detail/CardApplicationContainer";
import WrapGrid from "../../../View/Cards/Detail/WrapGrid";
import Label from "../../../View/Label/Label";
import CardContainerTitle from "../../../View/Cards/Detail/CardContainerTitle";
import CardContainerItemTitle from "../../../View/Cards/Detail/CardContainerItemTitle";
import CardItemDescription from "../../../View/Cards/Detail/CardItemDescription";
import CardPreloadImageContainer from "../../../View/Cards/Detail/CardPreloadImageContainer";
import CardPreloadImageItem from "../../../View/Cards/Detail/CardPreloadImageItem";
import Icon from "../../../View/Icon/Icon";
import { Link } from "react-router-dom";
import { openURl } from "../../../helpers/helpers";
import CardContainerControlCard from "../../../View/Cards/Detail/CardContainerControlCard";
import { bascketRed } from "../../../images";
import Button from "../../../View/Button/Button";

const DeleteMyApplicationCarDisassembled = ({ 
  dispatch,
  isDesktop,
  listApplications,
  handleDeleteApplication,  
}) => {
  return (
    <WrapContainer isDesktop={isDesktop}>
      <Offset mt={"var(--offset-top-desktop)"} />
      <Offset mt={15} style={{
         display: isDesktop? 'block' : 'block',
          width: isDesktop? '66%' : '100%',
          maxWidth: isDesktop? 'none' : '450'
        }
        
        }
      >

      {
          !listApplications.count && listApplications?.info?.status &&
        <p>{listApplications.info.message} {' '} {listApplications.count}</p>
      }
      {

        !!listApplications.count &&
        listApplications.results.map(item => {

          return (
            <CardApplicationContainer key={item.id}>
              <WrapGrid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to={''}
                  onClick={() => openURl(
                    item.user_data.user_url,
                    `Написать ${item.user_data.user_profile_name}`,
                    dispatch,
                    true
                  )}
                >
                <Label
                  style={{
                    fontSize: 12,
                    lineHeight: "10px",
                      color: '#1201ff'
                  }}
                  >
                  {item.user}
                </Label>                
                </Link>
              </WrapGrid>
              <Offset mt={16} />
             
                <CardContainerTitle>
                  <CardContainerItemTitle
                    style={{ color: "var(--text-color-red)" }}
                  >
                    {item.brand}
                  </CardContainerItemTitle>
                  <CardContainerItemTitle>
                    {item.model}
                  </CardContainerItemTitle>
                  <CardContainerItemTitle>
                    {item.generation}
                  </CardContainerItemTitle>
                </CardContainerTitle>
              
              <Offset mt={15} />
              <CardItemDescription>
                <Label
                  style={{
                    fontSize: 12,
                    lineHeight: "10px",
                  }}
                >
                  {item.text}
                </Label>
              </CardItemDescription>

              {item?.images && (
                <React.Fragment>
                  <Offset mt={24} />
                  <CardPreloadImageContainer>
                    {item.images.map((image, i) => {
                      return (
                        <CardPreloadImageItem
                          key={image.id}
                          onClick={(e) => { }}
                        >
                          <Icon
                            image={image.url}
                            width={63}
                            height={63}
                            style={{
                              backgroundRepeat: "repeat-y",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </CardPreloadImageItem>
                      );
                    })}
                  </CardPreloadImageContainer>
                </React.Fragment>
              )}
              <Offset mt={21} />
              <CardContainerControlCard
                style={{
                  justifyContent: "flex-end",
                  left: 0,
                }}
              >
                <Button
                  onClick={() => handleDeleteApplication(item.id) }
                  addClass={"button__application-control-card"}
                  iconRight={bascketRed}
                  style={{
                    color: "var(--text-color-red)",
                    letterSpacing: "0px",
                  }}
                  styleIconsRight={{
                    width: 14,
                    height: 14,
                    top: -2,
                    minWidth: 10,
                    marginRight: 7,
                  }}
                >
                  Удалить
                </Button>
              </CardContainerControlCard>
            </CardApplicationContainer>
          )
        })
      }
      </Offset>

    </WrapContainer>
  );
};
export default (DeleteMyApplicationCarDisassembled);
