import { activeButtonBootomForConfirm, getOptions, goToUp, handlerWarningInfoMessageResponse } from "../../helpers/helpers";
import { API_CREATE_REQUEST, API_CREATE_REQUEST_V2, API_CREATE_REQUEST_V3, API_DELETE_IMAGE_FROM_REQUEST, API_DELETE_REQUEST, API_DELETE_REQUEST_V2, API_DUBLICATE_REQUEST, API_DUBLICATE_REQUEST_V2, API_ENGINES_MARKET, API_GET_CARS, API_GET_COUNTRIES, API_GET_INFO_ENGINE, API_GET_PAGE, API_GET_REQUEST_AUTOPARTS, API_GET_REQUEST_BY_TYPE, API_GET_REQUEST_BY_TYPE_V2, API_GET_REQUEST_CATEGORY, API_GET_REQUEST_ENGINE, API_UPDATE_REQUEST, API_UPDATE_REQUEST_ENGINE, API_UPDATE_REQUEST_V2, DEFAULT_PAGE_SIZE, MAKE_REQUEST_MENU } from "../../helpers/config";
import { contentCopy, docSuccess } from "../../images";
import { ACTION_GET, ACTION_POST, DEBUG_TG_BOT, _INIT } from "../api-store/getpage";
import { ACTION_OPEN_MODAL, ACTION_SET_CONTROLL_BUTTON, ACTION_SET_CONTROLL_BUTTON_NULL } from "../helpers/helpers-store";
import { v4 } from "uuid";


export const ACTION_GET_LIST_BRANDS = 'getListBrands';
export const ACTION_GET_NEW_LIST_DATA = 'getChangeAggregateRequests';
export const ACTION_SET_LIST_BRANDS = 'setListBrands';
export const ACTION_SET_MY_APPLICATION = 'setPageMyApplication';
export const ACTION_SET_VALUE_UNIT_SPARE = 'setValuesUnitSpare';
export const RESET_VALUE_UNIT_SPARE = 'setValuesUnitSpareNull';
export const SEND_REQUEST = v4();
export const SEND_UPDATE_REQUEST = 'applyUpdateRequests';
export const ACTION_SET_LIST_MY_APPliCATION = 'setListMyApplication';
export const ACTION_GET_LIST_MY_APPliCATION = 'getListMyApplication';
export const ACTION_GET_LIST_MY_APPliCATION_SHOW_MORE = 'getListMyApplicationShoowMore';
export const ACTION_SET_LIST_COUNTRY = 'setListCountries';
export const ACTION_GET_LIST_COUNTRY = 'getListCountries';
export const ACTION_APPLY_ACTION_INTO_MY_APPLICATION = 'applyActionIntoMyApplication';
export const ACTION_DELETE_IMAGE_FROM_REQUEST = "deleteImageReqest";
export const ACTION_GET_PREPARE_PRODUTS = "getPrepare";
export const ACTION_SET_LIST_PREPARE_PRODUCTS = "setPrepare";
export const ACTION_RESET_LIST_PREPARE_PRODUCTS = "resetPrepare";
export const ACTION_GET_INFO_ENGINE = 'getInfoEngine';
export const ACTION_SET_INFO_ENGINE = 'setInfoEngine';
export const ACTION_INFO_ENGINE_NULL = "nullInfoEngine";
export const GET_LIST_OPTIONS_BY_TYPE = v4();
export const DELETE_ITEM_FROM_LIST_UNIT_SPARE = v4();
export const DELETE_AUTOPART_FROM_LIST_UNIT_SPARE = v4();
export const GET_APPlICATION_FROM_TYPE_ID = v4();
export const ACTION_SET_REQUEST_VALUE_UNIT_SPARE = v4();
export const REMOVE_CLASSES_FROM_LIST = v4();
export const SET_INIT_VALUE_UNIT_SPARE = v4();
export const RESET_INIT_VALUE_UNIT_SPARE = v4();


export const requests = store => {
  const initValuesUnitSpare = {
    options_year: [],// valuesUnitSpare?.options_year,
    year: "",
    options_brand: [],
    brand_id: "",
    options_model: [],
    model_id: "",
    options_volume: [],
    volume: "",
    options_petrol: [],
    petrol: "",
    options_power: [],
    power: "",
    options_body: [],
    body: "",
    options_engine: [],
    engine: "",
    
    options_autoparts: [],//valuesUnitSpare?.options_autoparts,

    text: '', //valuesUnitSpare.text,
    oem: "",
    phone_number: '',
    image: [], //valuesUnitSpare.images,
  };
  store.on(_INIT, () => ({ initValuesUnitSpare}));
  store.on(SET_INIT_VALUE_UNIT_SPARE, ({ initValuesUnitSpare }, payload) => ({ initValuesUnitSpare: { ...initValuesUnitSpare, ...payload } }));
  store.on(RESET_INIT_VALUE_UNIT_SPARE, () => ({ initValuesUnitSpare}));

  store.on(_INIT, () => ({ pageMyApplication: {} }));
  store.on(ACTION_SET_MY_APPLICATION, ({ pageMyApplication = {} }, data) => ({ pageMyApplication: { ...pageMyApplication, ...data } }));
  
  
  store.on(GET_APPlICATION_FROM_TYPE_ID, (_, data, {dispatch}) => {
    const { id, type } = data;
    let url = API_GET_REQUEST_BY_TYPE;
    if (data.type === "unit" || data.type === "spare") {
      url = API_GET_REQUEST_BY_TYPE_V2;
    }
    const params = {
      url,
      // id,
      type,
      dataRequst: res => {
        try {          
          if(res.results.filter(el=>+el.id === +id).length === 0) return dispatch(ACTION_OPEN_MODAL, {
        show: true,
        content: "Заявки с таким индификатором не существует",
        error: false,
      });
          dispatch(ACTION_SET_REQUEST_VALUE_UNIT_SPARE, {
            response: res.results.filter(el=>+el.id === +id)[0],
          });
          if(typeof data?.callback === 'function') data.callback();
        } catch (error) {
            console.log({error})          
        }
      },

    };
    dispatch(ACTION_GET, params);
  });
  
  store.on( ACTION_SET_REQUEST_VALUE_UNIT_SPARE, ({ valuesUnitSpare }, data = {}, { dispatch }) => {
    const { response } = data;
    valuesUnitSpare = {
      ...valuesUnitSpare,
      ...response,
      classes_list: [...response.classes.map(el=>el.complectation)],
      classes: [...response.classes.map(el=>({complectation_id: el.complectation.id, autoparts: el.complectation.autoparts}))]
    }
    return ({valuesUnitSpare})
  }
  );

  store.on( REMOVE_CLASSES_FROM_LIST, ({ valuesUnitSpare }, data = {}, { dispatch }) => {
    const { id,autoparts, screen } = data;
    if (valuesUnitSpare.classes_list.length === 1)
      return dispatch(ACTION_OPEN_MODAL, {
        show: true,
        content: "Вы не можете удалить \nпоследнюю комплектацию с сервера",
        error: false,
      });
    valuesUnitSpare = {
      ...valuesUnitSpare,
      classes: valuesUnitSpare.classes.filter(
        (el) =>
          +el.complectation_id !== +id &&
          el.autoparts !== autoparts
      ),
      classes_list: valuesUnitSpare.classes_list.filter(
        (el) => +el.id !== +id && +el.autoparts !== autoparts
      ),
    };
    setTimeout(() => dispatch(ACTION_SET_VALUE_UNIT_SPARE, {screen}),500);
    return ({valuesUnitSpare})
  }
  );
// *****************************************************************************

  store.on(GET_LIST_OPTIONS_BY_TYPE, (_, data, { dispatch }) => {
    const { type, screen } = data;
    let url = API_GET_CARS;
    if(type === 'categories') url = API_GET_REQUEST_CATEGORY;
    if(type === 'autoparts') url = API_GET_REQUEST_AUTOPARTS;
    let params = {
      dataRequst: res => {
        // if(type === 'year'){
        //   dispatch(DEBUG_TG_BOT, {
        //     type: "load year",
        //     text: 'Loading data from server complate',
        //   })
        // }
        // if (type === 'complectation') res = {...res, category_id: data.category_id};
        if (type === 'complectation') res = { ...res, autoparts: data.autoparts };
        dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
          type: `options_${type}`,
          response: res,
          screen,
        });
        dispatch(SET_INIT_VALUE_UNIT_SPARE, {
          [`options_${type}`]: res,
        })
        if (data?.getDataOptionsRequest) return data.getDataOptionsRequest(res);
        if(typeof data?.callback === 'function') data.callback();
      },
      url,
      ...data
    };
    if(type === 'year'){
      dispatch(DEBUG_TG_BOT, {
        type: "load year",
        text: 'Loading data from server start',
      })
    }
    dispatch(ACTION_GET, params);
  });

  const initValuesAggrigateUnitsSpare = {
    // isEngine: false,
    options_year: [],
    year: null,
    options_brand: [],
    brand_id: null,
    options_model: [],
    model_id: null,
    options_volume: [],
    volume: null,
    options_petrol: [],
    petrol: null,
    options_power: [],
    power: null,
    options_body: [],
    body: null,
    options_engine: [],
    engine: null,
    classes_list: [],
    classes: [],
    // options_categories: [],
    // category_id: null,
    options_autoparts: [],
    autoparts: [],
    autopart: null,
    text: null,
    oem: null,
    phone_number: null,
    engine_code: null,
    images: [],
    image: [],
    country_id: null,
    city_id: null,
    delete_request_images: [],
  };

  store.on(_INIT, () => ({ valuesUnitSpare: initValuesAggrigateUnitsSpare }));
  store.on(RESET_VALUE_UNIT_SPARE, ({ valuesUnitSpare }, payload) => { 
    if(payload !== null && payload !== undefined ){
      return {
        valuesUnitSpare : {
          ...initValuesAggrigateUnitsSpare,
          [payload]: valuesUnitSpare[payload]
        }
      }
    }
    return {valuesUnitSpare: initValuesAggrigateUnitsSpare} 
  });
  store.on(DELETE_AUTOPART_FROM_LIST_UNIT_SPARE, ({valuesUnitSpare}, data, {dispatch}) => {
    const { id, type, screen } = data;
    let tempAutoparts = valuesUnitSpare.classes[0].autoparts.filter((el) => el.id !== id);
    if(!tempAutoparts.length) tempAutoparts = [{
      id: 0,
      oem: ''
    }];
    const newValuesState = {
      ...valuesUnitSpare,
      classes: [
        ...valuesUnitSpare.classes.map(c => ({
          ...c,
          autoparts: tempAutoparts
        }))
      ]
    }
    setTimeout(() => dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      type,
      screen,
      response: newValuesState
    }),500);
    return {
      valuesUnitSpare: newValuesState,
    };
  });
  store.on(DELETE_ITEM_FROM_LIST_UNIT_SPARE, ({valuesUnitSpare}, data) => {
    // был id стал autoparts = {id, oem}
    return {
      valuesUnitSpare: {
        ...valuesUnitSpare,
        classes_list: valuesUnitSpare.classes_list.filter(
          (el) => el.id !== data.id
        ),
        classes: valuesUnitSpare.classes.filter(
          (el) => el.complectation_id !== data.id
        ),
      },
    };
  });
  store.on(ACTION_SET_VALUE_UNIT_SPARE, ({ valuesUnitSpare }, data={}, { dispatch }) => {
    
    const type = data?.type;
    const response = data?.response;
    const screen = data?.screen;
    function deepClone(obj) {
      if (obj instanceof File) return new File([obj], obj.name, { type: obj.type });;
      if (obj === null || typeof obj !== "object") return obj;
      if (obj instanceof Date) return new Date(obj);
      if (obj instanceof Array) return obj.map(deepClone);
      if (obj instanceof Map) return new Map([...obj.entries()].map(([k, v]) => [deepClone(k), deepClone(v)]));
      if (obj instanceof Set) return new Set([...obj].map(deepClone));

      let copy = {};
      for (let key in obj) {
        copy[key] = deepClone(obj[key]);
      }
      return copy;
    }
    if(valuesUnitSpare.hasOwnProperty(type)){
      if(typeof response === 'object'){
        valuesUnitSpare = {
          ...valuesUnitSpare,
          [type]: response.map((item) => deepClone(item)),
          // [type]: response.map((item) => structuredClone(item)),
        };
      }else{
        valuesUnitSpare = {
          ...valuesUnitSpare,
          [type]: response
        }
      }
    }
    let handTypeEngine = {};

    // обнуляем зависящие данные от верхнего уровня
    switch (type) {
      case "year":
        valuesUnitSpare = {
          ...valuesUnitSpare,
          options_brand: [],
          brand_id: null,
          options_model: [],
          model_id: null,
          options_volume: [],
          volume: null,
          options_petrol: [],
          petrol: null,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
      case "brand":
          valuesUnitSpare = {
          ...valuesUnitSpare,
          options_model: [],
          model_id: null,
          options_volume: [],
          volume: null,
          options_petrol: [],
          petrol: null,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
      case 'brand_id':
          valuesUnitSpare = {
          ...valuesUnitSpare,
          options_model: [],
          model_id: null,
          options_volume: [],
          volume: null,
          options_petrol: [],
          petrol: null,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
      case "model":
          valuesUnitSpare = {
          ...valuesUnitSpare,
          options_volume: [],
          volume: null,
          options_petrol: [],
          petrol: null,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
      case "model_id":
          valuesUnitSpare = {
          ...valuesUnitSpare,
          options_volume: [],
          volume: null,
          options_petrol: [],
          petrol: null,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
        case "volume":
          valuesUnitSpare = {
            ...valuesUnitSpare,
            options_petrol: [],
          petrol: null,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
        case "petrol":
          valuesUnitSpare = {
            ...valuesUnitSpare,
          options_power: [],
          power: null,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
      case "power":
        valuesUnitSpare = {
          ...valuesUnitSpare,
          options_body: [],
          body: null,
          options_engine: [],
          engine: null,
        };
        break;
      case "body":
        valuesUnitSpare = {
          ...valuesUnitSpare,          
          options_engine: [],
          engine: null,
        };
        break;
        
        case "engine_type_hand":
          valuesUnitSpare = {
            ...valuesUnitSpare,
            engine_code: response
          }
          break;
        case "options_complectation":// наполняем мультивыбор
        if(valuesUnitSpare?.engine_code) handTypeEngine = { engine_code: valuesUnitSpare?.engine_code};
        if (valuesUnitSpare?.year) handTypeEngine = { ...handTypeEngine, year: valuesUnitSpare?.year};
        valuesUnitSpare = {
          ...valuesUnitSpare,
          classes_list: [
            ...valuesUnitSpare.classes_list,
            {
              ...response,
            },
          ],
          classes: [
            ...valuesUnitSpare.classes,
            {
              complectation_id: response.id,
              autoparts: [{
                id: 0,
                oem: "",
              }],
              ...handTypeEngine
            },
          ],
          year: null,
          brand_id: null,
          model_id: null,
          volume: null,
          petrol: null,
          power: null,
          body: null,
          engine: null,
        };
        handTypeEngine = {};
        break;
      case "autoparts":
        const complactation = valuesUnitSpare.classes_list.map( c => ({
          ...c,
          autoparts: c?.autoparts ? [...c.autoparts, {
            name: response[0].name,
            oem: response[0].oem,
            id: response[0].id,
          }] : [{
            name: response[0].name,
            oem: response[0].oem,
            id: response[0].id,
          }]

        }))
        if (valuesUnitSpare.classes[0]?.engine_code) handTypeEngine = { engine_code: valuesUnitSpare.classes[0].engine_code };
        if (valuesUnitSpare.classes[0]?.year) handTypeEngine = { ...handTypeEngine,  year: valuesUnitSpare.classes[0].year };
        valuesUnitSpare = {
          ...valuesUnitSpare,
          classes_list: [...complactation],
          classes: [
            {
              // here zero into massive, because from the task one complictation
              complectation_id: valuesUnitSpare.classes[0].complectation_id,
              autoparts: [...valuesUnitSpare.classes[0].autoparts.filter(ap => ap.id !== response[0].id).filter(ap => ap?.id !== 0), {
                id: response[0].id,
                oem: response[0].oem,
              }],
              ...handTypeEngine
            },
          ],
        }
        handTypeEngine = {};
        break;
      case "autoparts-part":
        if (valuesUnitSpare.classes[0]?.engine_code) handTypeEngine = { engine_code: valuesUnitSpare.classes[0].engine_code };
        if (valuesUnitSpare.classes[0]?.year) handTypeEngine = { ...handTypeEngine, year: valuesUnitSpare.classes[0].year };
        valuesUnitSpare = {
          ...valuesUnitSpare,
          classes: [
            {
              complectation_id: valuesUnitSpare.classes[0].complectation_id,
              autoparts: [...valuesUnitSpare.classes[0].autoparts.filter(ap => ap.id !== response[0]?.id).filter(ap => ap?.id !== 0), {
                id: response[0].id,
                oem: response[0].oem,
              }],
              ...handTypeEngine
            },
          ],
        }
        handTypeEngine = {};
        break;
      case "autoparts-part-oem":
        if (valuesUnitSpare.classes[0]?.engine_code) handTypeEngine = { engine_code: valuesUnitSpare.classes[0].engine_code };
        if (valuesUnitSpare.classes[0]?.year) handTypeEngine = { ...handTypeEngine, year: valuesUnitSpare.classes[0].year };
        valuesUnitSpare = {
          ...valuesUnitSpare,
          classes: [
            {
              complectation_id: valuesUnitSpare.classes[0].complectation_id,
              autoparts: [...valuesUnitSpare.classes[0].autoparts.filter(ap => ap?.id !== 0).map(ap => ap.id === response[0].id ? {
                id: response[0].id,
                oem: response[0].oem,
              } : ap )],
              ...handTypeEngine
            },
          ],
        }
        handTypeEngine = {};
        break;
      case "autoparts-new-fild":
        if (valuesUnitSpare.classes[0]?.engine_code) handTypeEngine = { engine_code: valuesUnitSpare.classes[0].engine_code };
        if (valuesUnitSpare.classes[0]?.year) handTypeEngine = { ...handTypeEngine, year: valuesUnitSpare.classes[0].year };
        valuesUnitSpare = {
          ...valuesUnitSpare,
          classes: [
            {
              complectation_id: valuesUnitSpare.classes[0].complectation_id,
              autoparts: [...valuesUnitSpare.classes[0].autoparts.filter(ap => ap?.id), {
                id: 0,
                oem: ''
              }],
              ...handTypeEngine
            },
          ],
        }
        handTypeEngine = {};
        break;
      default:
        break;
    }
    
    const isActiveButton = activeButtonBootomForConfirm(
      { ...valuesUnitSpare, classes: valuesUnitSpare.classes.map(c => ({ ...c, autoparts: c.autoparts.filter(a => a.id !== 0) })) },
      screen,
    );
    if (isActiveButton) {
      dispatch(ACTION_SET_CONTROLL_BUTTON, {
        isActive: true,
        name: "Опубликовать запрос",
        action: () => {
          //  чистим от ненужных данных
          valuesUnitSpare = {
            ...valuesUnitSpare,
            options_year: [],
            options_brand: [],
            options_model: [],
            options_volume: [],
            options_petrol: [],
            options_power: [],
            options_body: [],
            options_engine: [],
            classes_list: [],
            options_categories: [],
            options_autoparts: [],
            engine_code: [],
            year: null,
            // category_id: null,
            autoparts: [],
            brand_id: null,
            model_id: null,
            volume: null,
            petrol: null,
            power: null,
            body: null,
            engine: null,
            classes: valuesUnitSpare.classes.map(c => ({...c,autoparts: c.autoparts.filter(a=>a.id!==0)}))
          };

          if (data?.screen.includes('edit_')) {
            dispatch(SEND_UPDATE_REQUEST, { 
              ...valuesUnitSpare, 
              type: screen, 
              callback: data?.callback 
            });
          } else {
            dispatch(SEND_REQUEST, {
              ...valuesUnitSpare,
              type: screen,
              callback: data?.callback,
            });
          }
          dispatch(RESET_VALUE_UNIT_SPARE, 'options_year');
          return dispatch(ACTION_SET_CONTROLL_BUTTON_NULL);
        },
      });
    } else {
      dispatch(ACTION_SET_CONTROLL_BUTTON, {
        isActive: false,
      });
    }
    console.log({valuesUnitSpare})
    return { valuesUnitSpare: valuesUnitSpare };
  });
  
  const initListPrepare = {
    count: 0,
    current_page: 0,
    results: [],
  };
  store.on(_INIT, () => ({
    listPrepare: initListPrepare,
  }));
  store.on(ACTION_SET_LIST_PREPARE_PRODUCTS, (_, data) => ({ listPrepare: data }));
  store.on(ACTION_RESET_LIST_PREPARE_PRODUCTS, (_, data) => ({
    listPrepare: initListPrepare,
  }));
  store.on(ACTION_GET_PREPARE_PRODUTS, ({ listPrepare }, data = {}, { dispatch }) => {
    try {
      //92405A7000
      const params = {
        url: API_ENGINES_MARKET,
        page_size: 4,
        page: data?.page ?? 1,
        blockButtonDisabled:true,
        dataRequst: (res) => {
          if (res === undefined) return;
          const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
          if (isWarning) return;
          dispatch(ACTION_SET_LIST_PREPARE_PRODUCTS, {
            ...listPrepare,
            ...res,
            results: data?.page? [...listPrepare.results, ...res.results] : res.results
          });
        },
        ...data,
      };
      dispatch(ACTION_GET, params);
    } catch (error) {
      console.log("erorr catch", error);
    }
  });

  store.on(_INIT, () => ({ listCountries: [] }));
  store.on(ACTION_SET_LIST_COUNTRY, (_, data) => ({ listCountries: [...data] }));
  store.on(ACTION_GET_LIST_COUNTRY, ({ _ }, data = {}, { dispatch }) => {
    try {

      const params = {
        url: API_GET_COUNTRIES,//'/api_get_requests/',
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
        // setIsLoading: status => data?.isLoading(status),
        dataRequst: res => {
          if (res === undefined) return;
          const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
          if (isWarning) return;
          dispatch(ACTION_SET_LIST_COUNTRY, res)
        },
        ...data
      };
      dispatch(ACTION_GET, params)

    } catch (error) {
      console.log('erorr catch', error)
    }
  });

  store.on(_INIT, () => ({ listMyApplication: {} }));
  store.on(ACTION_SET_LIST_MY_APPliCATION, (_, data) => ({ listMyApplication: { ...data } }));

  store.on(ACTION_GET_LIST_MY_APPliCATION, ({ pageMyApplication }, data = {}, { dispatch }) => {
    let url = API_GET_REQUEST_BY_TYPE;
    if (data.type === "unit" || data.type === "spare") {
      url = API_GET_REQUEST_BY_TYPE_V2;
    }
    const params = {
      url,
      page: pageMyApplication[data.type] ?? 1,
      page_size: DEFAULT_PAGE_SIZE,
      // setIsLoading: status => data?.isLoading(status),
      dataRequst: res => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        if(typeof data?.callback === 'function') data.callback();
        dispatch(ACTION_SET_MY_APPLICATION, {
          [data.type]: res.current_page
        })
        dispatch(ACTION_SET_LIST_MY_APPliCATION, res)
      },
      ...data
    };
    dispatch(ACTION_GET, params)

  });
  store.on(ACTION_GET_LIST_MY_APPliCATION_SHOW_MORE, ({ listMyApplication }, data = {}, { dispatch }) => {
     let url = API_GET_REQUEST_BY_TYPE;
     if (data.type === "unit" || data.type === "spare") {
       url = API_GET_REQUEST_BY_TYPE_V2;
     }
    const params = {
      url,
      page: data.page,
      page_size: DEFAULT_PAGE_SIZE,
      dataRequst: res => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        dispatch(ACTION_SET_LIST_MY_APPliCATION, { 
          ...listMyApplication, 
          ...res,
          results: [...listMyApplication.results,...res.results]
         });
      },
      ...data
    };
    dispatch(ACTION_GET, params)

  });


  store.on(SEND_UPDATE_REQUEST, (_, data, { dispatch }) => {
    dispatch(ACTION_SET_CONTROLL_BUTTON, {
      isFetch: true
    })
    data = {
      ...data,
      brand: "",
      model: "",
      generation: "",
      engine: "",
      date_create: "",
      user: "",
      images: [],
      request_id: data.id,
      id: '',
    };
    let url = API_UPDATE_REQUEST;
    if (data?.type === 'edit_unit' || data?.type === 'edit_spare') {
      url = API_UPDATE_REQUEST_V2;
    }
    // чичтим от ненужных данных перед отправкой на сервер
    const params = {
      url,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        if (typeof data?.callback === 'function') data.callback();
        dispatch(ACTION_OPEN_MODAL, {
          show: res?.info?.status,
          content: res?.info?.message,
          contentBtn: 'Ок',
          error: !res?.info?.status,
          path: MAKE_REQUEST_MENU,
          icon: docSuccess
        })

      },
      ...data,
    };
    dispatch(ACTION_POST, params);
  });

  store.on(SEND_REQUEST, (_, data, { dispatch }) => {
    dispatch(ACTION_SET_CONTROLL_BUTTON, {
      isFetch: true
    })
    let url = API_CREATE_REQUEST_V2;
    if(data.type === 'unit' || data.type === 'spare') url = API_CREATE_REQUEST_V3;
    let params = {
      url,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        dispatch(ACTION_OPEN_MODAL, {
          show: res?.info?.status,
          content: res?.info?.message,
          contentBtn: "Ок",
          error: !res?.info?.status,
          actionClickOk: () => {
            goToUp()
            typeof data?.callback === 'function' && data?.callback()
          },
          path: MAKE_REQUEST_MENU,
          icon: docSuccess,
        });
      },
      ...data,
    };
    dispatch(ACTION_POST, params);
  });

  store.on(ACTION_APPLY_ACTION_INTO_MY_APPLICATION, ({ pageMyApplication }, data, { dispatch }) => {
    const { action, id, type } = data;
    let params = {}
    let url = '';
    switch (action) {
      case 'copy':
        url = API_DUBLICATE_REQUEST;
        if (type === 'unit' || type ==='spare') url = API_DUBLICATE_REQUEST_V2;
        params = { url };
        break;
      case 'delete':
        url = API_DELETE_REQUEST;
        if (type === 'unit' || type ==='spare') url = API_DELETE_REQUEST_V2;
        params = { url }
        break;
      default: break;
    }
    params = {
      ...params,
      request_id: id,
      type,
      page: pageMyApplication[type] ?? 1,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        // const copyData = res?.slice();
        dispatch(ACTION_OPEN_MODAL, {
          show: res?.info?.status,
          content: res?.info?.message,
          contentBtn: 'Ок',
          error: !res?.info?.status,
          icon: contentCopy,
          actionClickOk: (e) => dispatch(ACTION_GET_LIST_MY_APPliCATION, { type })
        })
      },
    };
    dispatch(ACTION_GET, params);
  })




}