import { Form, Formik } from 'formik';
import WrapContainer from '../../../View/WrapContainer/WrapContainer';
import Label from '../../../View/Label/Label';
import Offset from '../../../View/Offset';
import TextArea from '../../../View/TextArea/TextArea';
import WithRouter from '../../../HOC/WithRouter';
import { truckSchema } from '../../../helpers/schemaValidations/schemaValidate';
import { messageErrorValidation } from '../../../helpers/schemaValidations/messgeSchemaValidations';
import { ACTION_SET_CONTROLL_BUTTON } from '../../../store/helpers/helpers-store';
import restrictionLengthText from '../../../helpers/helpers';
import WrapContainerPreloader from '../../../View/Preloaders/WrapContainerPreloader';
import Preloader from '../../../View/Preloaders/Preloader';
import ButtonApplyContainer from '../../../Components/ButtonApplySection/ButtonApplyContainer';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import { useEffect } from 'react';

const TransportRequest = ({
  edit,
  dispatch,
  isDesktop,
  valuesUnitSpare,
  saveValueStore,
}) => {

  return (
    <WrapContainer
      isDesktop={isDesktop}
      style={{
        height: isDesktop ? "max-content" : "auto",
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass={isDesktop ? "profile-desktop__self-info" : ""}>
        {edit && !valuesUnitSpare?.id ? (
          <WrapContainerPreloader>
            Загрузка ... <Preloader /> <Offset mb={30} />{" "}
          </WrapContainerPreloader>
        ) : (
          <Formik
            validationSchema={truckSchema(messageErrorValidation)}
            initialValues={{
              type: "transport",
              text: valuesUnitSpare?.text ?? "",
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
            }) => {
              useEffect(() => { dispatch(ACTION_SET_CONTROLL_BUTTON, { buttonForm: handleSubmit });  }, [dispatch])
              

              return (
                <Form>
                  <Offset mt={14} />
                  <Label style={{ fontWeight: 700 }}>Ваша задача</Label>
                  <Offset mt={7} />
                  <div>
                    <TextArea
                      className={"textarea-application"}
                      value={values.text}
                      placeholder={`Опишите задачу.\nВ тексте укажите город`}
                      height={90}
                      id={`textarea-1`}
                      name={"text"}
                      onBlur={handleBlur}
                      helptext={touched?.text && errors?.text}
                      style={{
                        border:
                          touched?.text && errors?.text
                            ? "1px solid #ff0000"
                            : "",
                      }}
                      stylehelptext={{
                        color: "var(--text-color-red)",
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const restrict = restrictionLengthText(
                          value,
                          4096,
                          dispatch
                        );
                        if (!restrict) return;
                        setFieldValue("text", value);
                        saveValueStore({ type: 'text',
                          response: value });
                      }}
                    />
                  </div>

                  <Offset mt={37} />
                </Form>
              );
            }}
          </Formik>
        )}
        {isDesktop && <Offset mt={30} />}
        {isDesktop && <ButtonApplyContainer isDesktop={isDesktop} />}
      </BlockGrid>
    </WrapContainer>
  );
};
export default (WithRouter(TransportRequest));
