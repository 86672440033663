import { Form, Formik } from "formik";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Label from "../../../View/Label/Label";
import Offset from "../../../View/Offset";
import TextArea from "../../../View/TextArea/TextArea";
import {
  addFile,
  bascketRed,
  plusFile,
} from "../../../images";
import FormUploadImageV2 from "../../../View/UploadImage/FormUploadImageV2";
import { messageErrorValidation } from "../../../helpers/schemaValidations/messgeSchemaValidations";
import { aggrigateSchema } from "../../../helpers/schemaValidations/schemaValidate";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import {
  setDataOptionsRequestFormik,
} from "../../../helpers/utils";
import WithTooltip from "../../../HOC/WithTooltip";
import restrictionLengthText, { goToUp } from "../../../helpers/helpers";
import Input from "../../../View/Input/Input";
import Icon from "../../../View/Icon/Icon";
import WrapTwoColumnGrid from "../../../View/Blocks/WrapTwoColumnGrid";
import WrapRowGrid from "../../../View/Blocks/WrapRowGrid";
import { delay } from "../../../helpers/const";
import React from "react";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";
import Button from "../../../View/Button/Button";

const UnitSpareRequest = ({
  isAddInfo,
  dispatch,
  isDesktop,
  initValues,
  handleAddInfo,
  saveValueStore,
  valuesUnitSpare,
  handleSparePart,
  handleCompectation,
  handleGetDataOption,
  isAddToListSparePart,
  handleDeleteAutoPartFromList,
}) => {
console.log({initValues})
  // const initValues = {
  //   options_year: valuesUnitSpare?.options_year,
  //   year: "",
  //   // autopart_id: "",
  //   options_autoparts: valuesUnitSpare?.options_autoparts,
  //   // autopart: valuesUnitSpare.autopart ?? [],
  //   options_brand: [],
  //   brand_id: 565,
  //   // brand: "",
  //   options_model: [],
  //   model_id: "",
  //   model: "",
  //   options_volume: [],
  //   volume: "",
  //   options_petrol: [],
  //   petrol: "",
  //   options_power: [],
  //   power: "",
  //   options_body: [],
  //   body: "",
  //   options_engine: [],
  //   engine: "",
  //   text: valuesUnitSpare.text,
  //   oem: "",
  //   phone_number: valuesUnitSpare.phone_number,
  //   image: valuesUnitSpare.images,
  // };
  return (
    <WrapContainer
      isDesktop={isDesktop}
      style={{
        height: isDesktop ? "max-content" : "auto",
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />

      <BlockGrid
        addClass={isDesktop ? "profile-desktop__self-info" : ""}
        style={{
          maxHeight: isDesktop ? `var(--height-field-request, 700px)` : "unset",
        }}
      >

        {
          !initValues?.options_year.length 
            ? (<WrapContainerPreloader>
                Загрузка... <Preloader /> <Offset mb={30} />{" "}
              </WrapContainerPreloader>) 
            : (
          <Formik
            validationSchema={aggrigateSchema(messageErrorValidation)}
                initialValues={ initValues}
                enableReinitialize={false}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              setValues,
              setErrors,
            }) => {
                  console.log(values, values.model_id)

              function getWrapStyle(touched, errors, type) {
                return {
                  border:
                    touched[type] && errors[type] ? "1px solid #ff0000" : "",
                };
              }

              function getGridAutoparts(amount){
                let stringAP = "'autopart_1 autopart_1 autopart_1 autopart_1 autopart_1 autopart_1'";
                if(amount > 1)
                  Array.from({ length: amount }, (_, i) => i + 1).map(el => stringAP += `\n'autopart_${el + 1} autopart_${el + 1} autopart_${el + 1} autopart_${el + 1} autopart_${el + 1} autopart_${el+1}'` )
                return stringAP;                
              }
              
              return (
                <>
                  <Offset mt={15} />
                  <Form
                    data-type="wrap-input"
                    className={
                      isDesktop
                        ? `request-unit-spare-desktop__container${valuesUnitSpare?.classes_list &&
                          valuesUnitSpare.classes_list.length > 0
                          ? "-preview"
                          : ""
                        }`
                        : ""
                    }
                    style={{
                      gridTemplateAreas: valuesUnitSpare?.classes_list &&
                        valuesUnitSpare.classes_list.length > 0 ?
                        `'preview preview preview preview preview preview'
                        'year year brand brand model model'
                  'volume volume petrol petrol power power'
                  'body body engine engine . .'
                  ${getGridAutoparts(valuesUnitSpare.classes[0]?.autoparts.length ?? 1)}
                  'add add . . . .'
                  'phone phone phone . . .'
                  'what-find what-find what-find upload upload upload'
                  '. . . . . .'
                  '. . . . . .'
                  'info_1 info_1 info_1 info_1 info_1 info_1'
                  'info_2 info_2 info_2 info_2 info_2 info_2'`
                        :`'year year brand brand model model'
                  'volume volume petrol petrol power power'
                  'body body engine engine . .'
                  ${getGridAutoparts(valuesUnitSpare.classes[0]?.autoparts.length ?? 1)}
                  'add add . . . .'
                  'phone phone phone . . .'
                  'what-find what-find what-find upload upload upload'
                  '. . . . . .'
                  '. . . . . .'
                  'info_1 info_1 info_1 info_1 info_1 info_1'
                  'info_2 info_2 info_2 info_2 info_2 info_2'`
                    }}
                  >
                      <>
                        {/* preview create items */}
                        <WrapRowGrid style={{ gridArea: "preview" }}>
                          {valuesUnitSpare?.classes_list &&
                            valuesUnitSpare.classes_list.length > 0 &&
                            valuesUnitSpare.classes_list.map((item, index) => (
                              <>
                              <WrapTwoColumnGrid
                                key={index}
                                style={{
                                  border: "1px solid",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  display: "flex",
                                  flexDirection: "column",
                                  position: "relative",
                                  marginBottom: 10,
                                }}
                              >                                
                                <Label
                                  style={{
                                    fontWeight: 700,
                                    paddingRight: "34px",
                                  }}
                                >
                                  {item.name}
                                </Label>
                                <Label>{`Год ${item.year_start} - ${item.year_end}`}</Label>
                                <Label>{`Объём двигателя ${item.engine_volume}`}</Label>
                                <Label>{`Тип топлива ${item.petrol_type}`}</Label>
                                <Label>{`Мощность ${item.engine_power}`}</Label>
                                <Label>{`Тип привода ${item.drive_type}`}</Label>
                                <Label>{`Тип кузова ${item.body_type}`}</Label>
                                <Label>{`Трансмиссия ${item.transmission}`}</Label>
                                <Label>{`Код двигателя ${item.engine_code}`}</Label>
                                <Label>{`Количество дверей ${item.doors_count}`}</Label>
                              </WrapTwoColumnGrid>                                                              
                              </>
                            ))}
                        </WrapRowGrid>
                       {
                        !(valuesUnitSpare?.classes_list &&
                            valuesUnitSpare.classes_list.length > 0) 
                            && <>
                            <ReactSelectComponent
                              label={"Год *"}
                              data={values.options_year}
                              isUpTop={isDesktop ? false : true}
                              name={"year"}
                              classNamePrefix={"request"}
                              clearValue={!!!values.year}
                              placeholder={values?.year ? values?.year : "Выберите год авто"}
                              onChange={(value) => {
                                setFieldValue("year", value);
                                setFieldValue("brand_id", "");
                                setFieldValue("model_id", "");
                                setFieldValue("volume", "");
                                setFieldValue("petrol", "");
                                setFieldValue("engine", "");
                                setFieldValue("power", "");
                                setFieldValue("body", "");
                                setFieldValue("options_brand", [])
                                setFieldValue("options_model", [])
                                setFieldValue("options_volume", [])
                                setFieldValue("options_petrol", [])
                                setFieldValue("options_power", [])
                                setFieldValue("options_body", [])
                                setFieldValue("options_engine", [])

                                saveValueStore({
                                  type: "year",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "brand",
                                  year: value,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_brand",
                                    });
                                  },
                                });
                              }}
                              helptext={touched?.year && errors?.year}
                              styleWrap={{
                                ...getWrapStyle(touched, errors, "year"),
                                gridArea: "year",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              label={"Бренд *"}
                              data={values.options_brand}
                              enabled={values.year}
                              isUpTop={isDesktop ? false : true}
                              name={"brand"}
                              // inputValue={values?.brand}
                              classNamePrefix={"request"}
                              clearValue={!!!values.brand_id}
                              placeholder={
                                values.options_brand.length && !!values?.brand_id 
                                  ? values.options_brand.filter(
                                    (el) => el.value === values.brand_id
                                  )[0].title
                                  : "Выберите бренд авто"
                              }
                              onChange={(value) => {
                                setFieldValue("brand_id", value);
                                // setFieldValue("model", "");
                                setFieldValue("model_id", "");
                                setFieldValue("volume", "");
                                setFieldValue("petrol", "");
                                setFieldValue("power", "");
                                setFieldValue("engine", "");
                                setFieldValue("body", "");                                
                                setFieldValue("options_model", [])
                                setFieldValue("options_volume", [])
                                setFieldValue("options_petrol", [])
                                setFieldValue("options_power", [])
                                setFieldValue("options_body", [])
                                setFieldValue("options_engine", [])
                                // setFieldValue(
                                //   "brand",
                                //   values.options_brand.filter(
                                //     (el) => el.value === value
                                //   )[0].title
                                // );
                                saveValueStore({
                                  type: "brand_id",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "model",
                                  brand_id: value,
                                  year: values.year,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_model",
                                    });
                                  },
                                });
                              }}
                              // helptext={touched?.brand && errors?.brand}
                              styleWrap={{
                                // ...getWrapStyle(touched, errors, "brand"),
                                gridArea: "brand",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              label={"Модель *"}
                              data={values.options_model}
                              enabled={values.brand_id}
                              isUpTop={isDesktop ? false : true}
                              name={"model"}
                              // inputValue={values?.model}
                              classNamePrefix={"request"}
                              clearValue={!!!values.model_id}
                              placeholder={
                                values.options_model.length && !!values?.model_id
                                  ? values.options_model.filter(
                                    (el) => el.value === values.model_id
                                  )[0].title
                                  : "Выберите модель авто"
                              }
                              onChange={(value) => {
                                setFieldValue("model_id", value);
                                setFieldValue("volume", "");
                                setFieldValue("petrol", "");
                                setFieldValue("power", "");
                                setFieldValue("engine", "");
                                setFieldValue("body", "");
                                setFieldValue("options_volume", [])
                                setFieldValue("options_petrol", [])
                                setFieldValue("options_power", [])
                                setFieldValue("options_body", [])
                                setFieldValue("options_engine", [])
                                // setFieldValue(
                                //   "model",
                                //   values.options_model.filter(
                                //     (el) => el.value === value
                                //   )[0].title
                                // );
                                saveValueStore({
                                  type: "model_id",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "volume",
                                  year: values.year,
                                  model_id: value,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_volume",
                                    });
                                  },
                                });
                              }}
                              // helptext={touched?.model && errors?.model}
                              styleWrap={{
                                // ...getWrapStyle(touched, errors, "model"),
                                gridArea: "model",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              label={"Объём двигателя *"}
                              data={values.options_volume}
                              enabled={values.model_id}
                              isUpTop={isDesktop ? false : true}
                              name={"volume"}
                              // inputValue={values?.volume}
                              classNamePrefix={"request"}
                              clearValue={!!!values.volume}
                              placeholder={
                                   values?.volume 
                                    ? values?.volume 
                                    : "Выберите объём двигателя"
                              }
                              onChange={(value) => {
                                setFieldValue("volume", value);
                                setFieldValue("petrol", "");
                                setFieldValue("power", "");
                                setFieldValue("engine", "");
                                setFieldValue("body", "");
                                setFieldValue("options_petrol", [])
                                setFieldValue("options_power", [])
                                setFieldValue("options_body", [])
                                setFieldValue("options_engine", [])
                                saveValueStore({
                                  type: "volume",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "petrol",
                                  year: values.year,
                                  model_id: values.model_id,
                                  volume: value,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_petrol",
                                    });
                                  },
                                });
                              }}
                              // helptext={touched?.volume && errors?.volume}
                              styleWrap={{
                                // ...getWrapStyle(touched, errors, "volume"),
                                gridArea: "volume",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              label={"Тип топлива *"}
                              data={values.options_petrol}
                              enabled={values.volume}
                              isUpTop={isDesktop ? false : true}
                              name={"petrol"}
                              // inputValue={values?.petrol}
                              classNamePrefix={"request"}
                              clearValue={!!!values.petrol}
                              placeholder={
                                values?.petrol ? values?.petrol : "Выберите тип топлива"
                              }
                              onChange={(value) => {
                                setFieldValue("petrol", value);
                                setFieldValue("power", "");
                                setFieldValue("engine", "");
                                setFieldValue("body", "");
                                setFieldValue("options_power", [])
                                setFieldValue("options_body", [])
                                setFieldValue("options_engine", [])
                                saveValueStore({
                                  type: "petrol",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "power",
                                  volume: values.volume,
                                  model_id: values.model_id,
                                  year: values.year,
                                  petrol: value,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_power",
                                    });
                                  },
                                });
                              }}
                              helptext={touched?.petrol && errors?.petrol}
                              styleWrap={{
                                ...getWrapStyle(touched, errors, "petrol"),
                                gridArea: "petrol",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              label={"Мощность *"}
                              data={values.options_power}
                              enabled={values.petrol}
                              isUpTop={isDesktop ? false : true}
                              name={"power"}
                              // inputValue={values?.power}
                              classNamePrefix={"request"}
                              clearValue={!!!values.power}
                            placeholder={values?.power ? values?.power : "Выберите мощность"}
                              onChange={(value) => {
                                setFieldValue("power", value);
                                setFieldValue("body", "");
                                setFieldValue("engine", "");
                                setFieldValue("options_body", [])
                                setFieldValue("options_engine", [])
                                saveValueStore({
                                  type: "power",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "body",
                                  year: values.year,
                                  model_id: values.model_id,
                                  volume: values.volume,
                                  petrol: values.petrol,
                                  power: value,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_body",
                                    });
                                  },
                                });
                              }}
                              helptext={touched?.power && errors?.power}
                              styleWrap={{
                                ...getWrapStyle(touched, errors, "power"),
                                gridArea: "power",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              label={"Тип кузова *"}
                              data={values.options_body}
                              enabled={values.power}
                              isUpTop={isDesktop ? false : true}
                              name={"body"}
                              // inputValue={values?.body}
                              classNamePrefix={"request"}
                              clearValue={!!!values.body}
                              placeholder={
                                values?.body ? values?.body : "Выберите тип кузова"
                              }
                              onChange={async (value) => {
                                setFieldValue("body", value);
                                setFieldValue("options_engine", [])
                                saveValueStore({
                                  type: "body",
                                  response: value,
                                });
                                handleGetDataOption({
                                  type: "engine",
                                  year: values.year,
                                  model_id: values.model_id,
                                  volume: values.volume,
                                  petrol: values.petrol,
                                  power: values.power,
                                  body: value,
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_engine",
                                    });
                                  },
                                });
                              }}
                              helptext={touched?.body && errors?.body}
                              styleWrap={{
                                ...getWrapStyle(touched, errors, "body"),
                                gridArea: "body",
                              }}
                            />
                            {!isDesktop && <Offset mt={10} />}
                            <ReactSelectComponent
                              creatableSelect
                              label={"Двигатель *"}
                              data={values.options_engine}
                              enabled={values.body}
                              isUpTop={isDesktop ? false : true}
                              name={"engine"}
                              // inputValue={values?.engine}
                              classNamePrefix={"request"}
                            clearValue={!!!values.engine}
                            placeholder={values?.engine ? values?.engine : "Выберите или введите маркировку вручную"}
                              onChange={(value, action) => {
                                setFieldValue("engine", value);
                                setFieldValue("power", "");
                                setFieldValue("body", "");
                                action === "create-option" && 
                                saveValueStore({
                                  type: "engine_type_hand",
                                  response: value,
                                });
                                handleCompectation();
                                handleGetDataOption({
                                  type: "complectation",
                                  year: values.year,
                                  model_id: values.model_id,
                                  volume: values.volume,
                                  petrol: values.petrol,
                                  power: values.power,
                                  body: values.body,
                                  engine: value,
                                  getDataOptionsRequest: async (res) => {
                                    setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: res,
                                      type: "data_complectation",
                                    });
                                    await delay(700);
                                    goToUp();
                                    setFieldValue("year", "");
                                    // setFieldValue("brand", "");/
                                    // setFieldValue("model", "");
                                    setFieldValue("brand_id", "");
                                    setFieldValue("model_id", "");
                                    setFieldValue("volume", "");
                                    setFieldValue("petrol", "");
                                    setFieldValue("power", "");
                                    setFieldValue("body", "");
                                    setFieldValue("engine", "");
                                    return;
                                  },
                                });
                                handleGetDataOption({
                                  type: "autoparts",                                  
                                  getDataOptionsRequest: (res) => {
                                    const copyData = res.slice();
                                    return setDataOptionsRequestFormik({
                                      setFieldValue,
                                      results: copyData,
                                      type: "options_autoparts",
                                    });
                                  },
                                });
                              }}
                              helptext={touched?.engine && errors?.engine}
                              styleWrap={{
                                ...getWrapStyle(touched, errors, "engine"),
                                gridArea: "engine",
                              }}
                            />
                          </>}
                              {/* options spare parts | oem */}
                            <Offset mt={20} />
                      {!!valuesUnitSpare.classes.length && valuesUnitSpare.classes[0].autoparts.map(( c ,i)=>
                        <div 
                          key={c?.id} 
                          style={{ 
                            position: 'relative', 
                            gridArea: "autopart_" + (i+ 1), 
                            gridTemplateAreas: `'spare spare spare oem oem oem'`,
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                            display: isDesktop ? 'grid' : '',
                            gap: 10,
                          }}
                        >
                          {!isDesktop && <Offset mt={10} />}
                              <ReactSelectComponent
                                key={isAddToListSparePart}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Hello world!"
                                label={"Запчасть *"}
                                data={values.options_autoparts}
                                enabled={valuesUnitSpare.classes_list.length > 0}
                                isUpTop={isDesktop ? false : true}
                                name={"autopart"}
                                classNamePrefix={"request"}
                                clearValue={!true}
                                placeholder={values.options_autoparts.filter(oa => oa.id === c.id)[0]?.title ?? "Выберите запчасть"}
                                onChange={(value) => {
                                  // setFieldValue("autopart_id", value);
                                  // setFieldValue(
                                  //   "autopart", values.options_autoparts.filter(
                                  //     (el) => el.value === value
                                  //   )[0].title
                                  // ); 
                                  handleDeleteAutoPartFromList(c.id)  
                                  saveValueStore({
                                    type: "autoparts-part",
                                    response: [{
                                      id: value,
                                      oem: values?.oem ?? '',
                                      name: values.options_autoparts.filter(
                                        (el) => el.value === value
                                      )[0].title,
                                      engine: values.engine,
                                      year: values?.year,
                                    }],
                                  });                             
                                }}
                                // helptext={touched?.autopart && errors?.autopart}
                                styleWrap={{
                                  gridArea: 'spare',
                                  // ...getWrapStyle(touched, errors, "autopart"),                                
                                }}

                              />      
                            {
                              !!(c?.id) && 
                                <Icon
                                  image={bascketRed}
                                  onClick={() => {
                                    // setFieldValue("autopart_id", "");
                                    // setFieldValue("autopart", "");
                                    setFieldValue("oem", "");
                                    handleDeleteAutoPartFromList(valuesUnitSpare.classes[0].autoparts[i].id)
                                  }
                                  }
                                  style={{
                                    // gridArea: "del-spare-parts_" + id,
                                    position: "absolute",
                                    right: 0,
                                    top: isDesktop? 0:0,
                                  }}
                                />        
                            }              
                            <Tooltip id="my-tooltip" />
                            {!isDesktop && <Offset mt={10} />}
                            <Offset
                              style={{ 
                                gridArea: "oem", 
                                maxWidth: "100%" }}
                            >
                              <Input
                                className={"textarea-application"}
                                disabled={!(c?.id !==0 )}
                                restriction={20}
                                key={isAddToListSparePart}
                                placeholder={!!c?.oem? c.oem : `Введите OEM`}
                                height={44}
                                label={"OEM"}
                                styleLabel={{
                                  fontWeight: 700,
                                }}
                                distationtop={200}
                                name={"oem"}
                                style={{
                                  border:
                                    touched?.oem && errors?.oem
                                      ? "1px solid #ff0000"
                                      : "",
                                }}
                                styleWrap={{
                                  border:
                                    touched?.oem && errors?.oem
                                      ? "1px solid #ff0000"
                                      : "1px solid var(--border-select-color)",
                                }}
                                helptext={touched?.oem && errors?.oem}
                                stylehelptext={{
                                  color: "var(--text-color-red)",
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue("oem", value);
                                  saveValueStore({
                                    type: "autoparts-part-oem",
                                    response: [{
                                      id: c.id,
                                      engine: values.engine,
                                      year: values?.year,
                                      oem: value ?? '',                                      
                                    }],
                                  }); 
                                }}
                              />
                            </Offset>
                          </div>
                      )}

                        {/* button add  spare parts */}
                        <> 
                          <Offset mt={20} />

                          <Button
                            addClass={"button__edit-my-profile--full"}
                          disabled={!(valuesUnitSpare.classes_list.length > 0 && !!valuesUnitSpare.classes[0]?.autoparts?.length)}
                            style={{ gridArea: "add", color: !(valuesUnitSpare.classes_list.length > 0) && '#eeeeee'  }}
                            onClick={() => {
                              // setFieldValue("autopart_id", "");
                              // setFieldValue("autopart", "");
                              setFieldValue("oem", "");
                              saveValueStore({
                                type: "autoparts-new-fild",                                
                              });
                            }
                            }
                          >
                            {'Добавить запчасть'}
                          </Button>
                        </>
                      
                        {!isAddInfo && <>
                          <Offset mt={20} />

                          <Button
                            addClass={"button__edit-my-profile--full"}
                            style={{ gridArea: "add-info"}}
                            onClick={() => handleAddInfo()}                            
                          >
                            {'Добавить информацию'}
                          </Button>
                        </>}
                        {isAddInfo && <>
                          <Offset
                            mt={10}
                            style={{ gridArea: "phone", maxWidth: "100%" }}
                          >
                            <Input
                              className={"textarea-application"}
                              value={values.phone_number}
                              placeholder={`📞 Введите телефон, если необходимо:`}
                              height={40}
                              label={"Телефон"}
                              styleLabel={{
                                fontWeight: 700,
                              }}
                              distationtop={200}
                              name={"phone_number"}
                              style={{
                                border:
                                  touched?.oem && errors?.oem
                                    ? "1px solid #ff0000"
                                    : "",
                              }}
                              styleWrap={{
                                border:
                                  touched?.phone_number && errors?.phone_number
                                    ? "1px solid #ff0000"
                                    : "1px solid var(--border-select-color)",
                              }}
                              helptext={
                                touched?.phone_number && errors?.phone_number
                              }
                              stylehelptext={{
                                color: "var(--text-color-red)",
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue("phone_number", value);
                                saveValueStore({
                                  type: "phone_number",
                                  response: value,
                                });
                              }}
                            />
                          </Offset>

                          <Offset mt={10} />

                          <div
                            className={
                              isDesktop ? "request-repair-desktop__wrap-text" : ""
                            }
                          >
                            <TextArea
                              className={"textarea-application"}
                              value={values.text}
                              placeholder={`Опишите ваш запрос
если необходимо дополнительное описание`}
                              height={90}
                              label={"Ваш запрос"}
                              styleLabel={{
                                fontWeight: 700,
                              }}
                              distationtop={200}
                              id={`textarea-1`}
                              onBlur={handleBlur}
                              name={"text"}
                              onChange={(e) => {
                                const value = e.target.value;
                                const restrict = restrictionLengthText(
                                  value,
                                  4096,
                                  dispatch
                                );
                                if (!restrict) return;
                                setFieldValue("text", value);
                                saveValueStore({
                                  type: "text",
                                  response: value,
                                });
                              }}
                            />
                          </div>
                          <FormUploadImageV2
                            multiple
                            className={
                              "request-repair-desktop__wrap-upload-images"
                            }
                            title="Прикрепить изображение **"
                            image={addFile}
                            listImages={
                              isDesktop
                                ? [
                                  { url: plusFile, type: "fake-image" },
                                  ...values.image,
                                ]
                                : [...values.image]
                            }
                            values={values}
                            maxCountImage={8}
                            maxSizeImage={5242880} // 5242880 => 5mb
                            setFieldValue={setFieldValue}
                            uploadTypeName="image"
                            positionPreview={isDesktop ? "over" : "under"}
                            onChange={({ key, value, callback }) => {
                              console.log({value})
                              saveValueStore({
                                type: key,
                                response: value,
                              });
                            }}
                          />
                        </>}
                      </>
                    <Offset mt={30} />

                    <Label style={{ fontWeight: 200, opacity: .5, gridArea: "info_1", }}>* - обязательные поля для заполнения</Label>
                    <Label style={{ fontWeight: 200, opacity: .5, gridArea: "info_2", }}>** - Картинка лучше тысячи слов! Прикрепите изображение, если это возможно.</Label>
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
        <Offset mt={30} />
        {isDesktop && <Offset mt={30} />}
        {isDesktop && <ButtonApplyContainer isDesktop={isDesktop} />}
      </BlockGrid>
    </WrapContainer>
  );
};
export default (WithTooltip(UnitSpareRequest));