import { Form, Formik } from "formik";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import { ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import React, { useEffect } from "react";
import CardApplicationContainer from "../../../View/Cards/Detail/CardApplicationContainer";
import WrapGrid from "../../../View/Cards/Detail/WrapGrid";
import Label from "../../../View/Label/Label";
import CardContainerTitle from "../../../View/Cards/Detail/CardContainerTitle";
import CardContainerItemTitle from "../../../View/Cards/Detail/CardContainerItemTitle";
import CardItemDescription from "../../../View/Cards/Detail/CardItemDescription";
import CardPreloadImageContainer from "../../../View/Cards/Detail/CardPreloadImageContainer";
import CardPreloadImageItem from "../../../View/Cards/Detail/CardPreloadImageItem";
import Icon from "../../../View/Icon/Icon";
import { Link } from "react-router-dom";
import { openURl } from "../../../helpers/helpers";

const SearchCarDisassembled = ({ 
  dispatch,
  isDesktop,
  listBrands,
  listModel,
  listGeneration,
  handleGetDataType,
  handleSetDataValues,
  listFindedCarDisassembled,
}) => {
  if (!!!listBrands.length) {
    return <WrapContainerPreloader>
      Загрузка... <Preloader /> <Offset mb={30} />{" "}
    </WrapContainerPreloader>
  }
  return (
    <WrapContainer isDesktop={isDesktop}>
      <Offset mt={"var(--offset-top-desktop)"} />
      <Formik
        initialValues={{
          brand: [...listBrands],
          model: [...listModel],
          generation: [...listGeneration],
          brand_id: '',
          model_id: '',
          generation_id: '',         
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
        }) => {
          useEffect(() => { dispatch(ACTION_SET_CONTROLL_BUTTON, { buttonForm: handleSubmit });  }, [dispatch])

          return (
            <Form style={{
              width: isDesktop? "100%": "",
              display: isDesktop? "grid" : "",
              gridTemplateColumns: isDesktop? "1fr 1fr 1fr 1fr 1fr 1fr ": "",
              gridGap: "20px",
              gridTemplateAreas: isDesktop? `
                "brand brand model model generation generation"
              ` : '',
            }}>
              {!isDesktop  && <Offset mt={15} />}
              <ReactSelectComponent
                label={"Марка *"}
                data={values.brand}
                enabled={true}
                isUpTop={true}
                name={"brand"}
                classNamePrefix={"request"}
                // clearValue={!!!values.power}
                placeholder={"Выберите марку"}
                onChange={(value) => {
                  setFieldValue("brand_id", value);
                  setFieldValue("model_id", '');
                  setFieldValue("generatin_id", '');
                  handleGetDataType({
                    type: 'model',
                    setFieldValue,
                    brand_id: value,
                  });
                  handleSetDataValues({
                    'brand_id': value,
                    'model_id': '',
                    'generation_id': '',
                  })
                }}
                styleWrap={{
                  gridArea: 'brand',
                }}
              />
              {!isDesktop && <Offset mt={15} />}
              <ReactSelectComponent
                label={"Модуль *"}
                data={values.model}
                enabled={values.brand_id}
                isUpTop={true}
                name={"modal"}
                classNamePrefix={"request"}
                clearValue={values.brand_id}
                placeholder={"Выберите модель"}
                onChange={(value, action) => {
                  console.log({ value })
                  setFieldValue("model_id", value);
                  setFieldValue("generatin_id", '');
                  handleGetDataType({
                    type: 'generation',
                    setFieldValue,
                    brand_id: values.brand_id,
                    model_id: value,
                  });
                  handleSetDataValues({
                    'brand_id': values.brand_id,
                    'model_id': value,
                    'generation_id': '',
                  })
                }}
                styleWrap={{
                  gridArea: 'model',
                }}
              />
              {!isDesktop && <Offset mt={15} />}
              <ReactSelectComponent
                label={"Поколение *"}
                data={values.generation}
                enabled={values.model_id}
                isUpTop={true}
                name={"generation"}
                classNamePrefix={"request"}
                clearValue={values.model_id || values.brand_id}
                placeholder={"Выберите поколение"}
                onChange={(value, action) => {
                  setFieldValue("generation_id", value);
                  handleSetDataValues({
                    'brand_id': values.brand_id,
                    'model_id': values.model_id,
                    'generation_id': value,
                  })
                }}
                styleWrap={{
                  gridArea: 'generation',
                }}
              />
           
            </Form>
          );
        }}
      </Formik>
      <Offset mt={15} style={{
         display: isDesktop? 'block' : 'block',
          width: isDesktop? '66%' : '100%',
          maxWidth: isDesktop? 'none' : '450'
        }
        
        }
      >

      {
        !listFindedCarDisassembled.count && listFindedCarDisassembled?.info?.status &&
        <p>{listFindedCarDisassembled.info.message} {' '} {listFindedCarDisassembled.count}</p>
      }
      {

        !!listFindedCarDisassembled.count &&
        listFindedCarDisassembled.results.map(item => {

          return (
            <CardApplicationContainer key={item.id}>
              <WrapGrid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to={''}
                  onClick={() => openURl(
                    item.user_data.user_url,
                    `Написать ${item.user_data.user_profile_name}`,
                    dispatch,
                    true
                  )}
                >
                <Label
                  style={{
                    fontSize: 12,
                    lineHeight: "10px",
                      color: '#1201ff'
                  }}
                  >
                  {item.user}
                </Label>                
                </Link>
              </WrapGrid>
              <Offset mt={16} />
             
                <CardContainerTitle>
                  <CardContainerItemTitle
                    style={{ color: "var(--text-color-red)" }}
                  >
                    {item.brand}
                  </CardContainerItemTitle>
                  <CardContainerItemTitle>
                    {item.model}
                  </CardContainerItemTitle>
                  <CardContainerItemTitle>
                    {item.generation}
                  </CardContainerItemTitle>
                </CardContainerTitle>
              
              <Offset mt={15} />
              <CardItemDescription>
                <Label
                  style={{
                    fontSize: 12,
                    lineHeight: "10px",
                  }}
                >
                  {item.text}
                </Label>
              </CardItemDescription>

              {item?.images && (
                <React.Fragment>
                  <Offset mt={24} />
                  <CardPreloadImageContainer>
                    {item.images.map((image, i) => {
                      return (
                        <CardPreloadImageItem
                          key={image.id}
                          onClick={(e) => { }}
                        >
                          <Icon
                            image={image.url}
                            width={63}
                            height={63}
                            style={{
                              backgroundRepeat: "repeat-y",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </CardPreloadImageItem>
                      );
                    })}
                  </CardPreloadImageContainer>
                </React.Fragment>
              )}
              <Offset mt={21} />
              {/* <CardContainerControlCard
                style={{
                  justifyContent: "flex-start",
                  left: 0,
                }}
              >
                <Button
                  onClick={() =>
                    handlerActionMyApplication(
                      ACTION_DELETE,
                      item.id,
                      item.type.type
                    )
                  }
                  addClass={"button__application-control-card"}
                  iconRight={bascketRed}
                  style={{
                    color: "var(--text-color-red)",
                    letterSpacing: "0px",
                  }}
                  styleIconsRight={{
                    width: 14,
                    height: 14,
                    top: -2,
                    minWidth: 10,
                    marginRight: 7,
                  }}
                >
                  Удалить
                </Button>
                <Button
                  onClick={() =>
                    handlerActionMyApplication(
                      "copy",
                      item.id,
                      item.type.type
                    )
                  }
                  addClass={"button__application-control-card"}
                  iconRight={copyDoc}
                  style={{
                    color: "var(--text-color-rlight-blue)",
                    letterSpacing: "0px",
                  }}
                  styleIconsRight={{
                    width: 14,
                    height: 14,
                    top: -1,
                    minWidth: 10,
                    marginRight: 10,
                  }}
                >
                  Дублировать
                </Button>
                <Button
                  onClick={() =>
                    handlerActionMyApplication(
                      "edit",
                      item.id,
                      item.type.type
                    )
                  }
                  addClass={"button__application-control-card"}
                  iconRight={penGrey}
                  style={{
                    color: "var(--text-color-rlight-blue)",
                    letterSpacing: "0px",
                  }}
                  styleIconsRight={{
                    width: 14,
                    height: 14,
                    top: -1,
                    minWidth: 10,
                    marginRight: 10,
                  }}
                >
                  Редактировать
                </Button>
              </CardContainerControlCard> */}
            </CardApplicationContainer>
          )
        })
      }
      </Offset>

    </WrapContainer>
  );
};
export default (SearchCarDisassembled);
