import React from 'react'
import styles from '../styles/application.module.scss'
export default function CardApplicationContainer({children, ...props}) {
  return (
    <div
      className={styles['application__container']}
      {...props}
    >{children}</div>
  )
}
