import { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { serializerListOption } from "../../../helpers/helpers";
import styles from "./styles/option-select.module.scss";
import "./styles/custom-style-react-select.scss";
import Label from "../../Label/Label";
import Offset from "../../Offset";

const ReactSelectComponent = ({
  pt = 0,
  pb = 0,
  pl = 11,
  pr = 5,
  mt,
  mb,
  ml,
  mr,
  id,
  data = [],
  label,
  name = "select",
  onBlur = () => {},
  enabled = true,
  isUpTop = false,
  onChange,
  helptext = "",
  styleWrap={},
  clearValue,
  placeholder,
  stylehelptext = {},
  creatableSelect = false,
  classNamePrefix = "",
  noOptionsMessage,
  ...props
}) => {
  let [offsetTop, setOffsetTop] = useState(false);
  const selectRef = useRef(null);

  const handlerIsOpenMenu = (status) => {
    if (status) {
      document.documentElement.style.setProperty("--opacity-fone", ".1");
      return setOffsetTop(true);
    }
    document.documentElement.style.setProperty("--opacity-fone", "1");
    setOffsetTop(false);
  };

  useEffect(() => {
    if (clearValue) {
      selectRef.current.setValue({ label: placeholder });
    }
  }, [clearValue]);

  return (
    <>
      <div
        style={{
          ...styleWrap,
        }}
        className={
          offsetTop
          ? styles["option-select__container--offset-top"]
          : styles["option-select__container"]
        }
        >
        {label && <Label style={{fontWeight: 700}}>{label}</Label>}
        {label && <Offset mb={7} />}
        {
          creatableSelect?
        <CreatableSelect
          isClearable
          classNamePrefix={classNamePrefix}
          options={serializerListOption(data)}
          hideSelectedOptions={true} // убераем выбраные опции из списка
          isDisabled={!enabled}
          name={name}
          placeholder={placeholder}
          id={id}
          onChange={(data, action) => {
            console.log({action})
            if (data?.value === null) return; // ????????? пулит лишний запрос
            if (data?.value === undefined) return; // ????????? пулит лишний запрос
            return onChange(data?.value, action?.action);
          }}
          ref={selectRef}
          onMenuOpen={(e) => isUpTop && handlerIsOpenMenu(true)}
          onMenuClose={(e) => isUpTop && handlerIsOpenMenu(false)}
          noOptionsMessage={() => noOptionsMessage  ?? !!!data.length ? 'Список пуст' : "Текста с таким названием нет в списке"}
          formatCreateLabel={(inputValue) => `Выбрать: "${inputValue}"`} // Изменяем текст
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999, // Убедитесь, что вложенные меню могут быть поверх родителя
            }),
          }}
          {...props}
        />
        :
        <ReactSelect
          classNamePrefix={classNamePrefix}
          options={serializerListOption(data)}
          hideSelectedOptions={true} // убераем выбраные опции из списка
          isDisabled={!enabled}
          name={name}
          placeholder={placeholder}
          id={id}
          onChange={(data) => {
            if (data?.value === null) return; // ????????? пулит лишний запрос
            if (data?.value === undefined) return; // ????????? пулит лишний запрос
            return onChange(data?.value);
          }}
          ref={selectRef}
          onMenuOpen={(e) => isUpTop && handlerIsOpenMenu(true)}
          onMenuClose={(e) => isUpTop && handlerIsOpenMenu(false)}
          noOptionsMessage={() => noOptionsMessage  ?? !!!data.length ? 'Список пуст' : "Текста с таким названием нет в списке"}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999, // Убедитесь, что вложенные меню могут быть поверх родителя
            }),
          }}
          {...props}
        />
        }

        {helptext ? (
          <div
            style={{
              position: "absolute",
              bottom: -18,
              left: 0,
              color: "var(--text-color-red)",
              ...stylehelptext,
            }}
          >
            {helptext}
          </div>
        ) : null}
      </div>
      {offsetTop && <div style={{ minHeight: 38 }}></div>}
    </>
  );
};

export default ReactSelectComponent;
