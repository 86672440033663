import React, { useId } from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import {
  autoFocusFormInput,
  checkEmptyDataObject,
  removeFocusFormInput,
  setSessionStore,
} from "../../helpers/utils";
import WrapContainerPreloader from "../../View/Preloaders/WrapContainerPreloader";
import Offset from "../../View/Offset";
import Preloader from "../../View/Preloaders/Preloader";
import HeaderTitleActionComponent from "../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent";
import BlockGrid from "../../View/Blocks/BlockGrid";
import TextArea from "../../View/TextArea/TextArea";
import Label from "../../View/Label/Label";
import { penGrey } from "../../images";
import Input from "../../View/Input/Input";
import ErrorMessage from "../../View/ErrorMessage/ErrorMessage";
import Icon from "../../View/Icon/Icon";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneContainer from "../../View/PhoneContainer/PhoneContainer";
import Form from "../../View/Form/Form";
import { ACTION_SET_CONTROLL_BUTTON } from "../../store/helpers/helpers-store";
import { Formik } from "formik";
import { editProfile } from "../../helpers/schemaValidations/schemaValidate";
import { messageErrorValidation } from "../../helpers/schemaValidations/messgeSchemaValidations";
import Phone from "react-phone-number-input/input";
import ButtonApplyContainer from "../../Components/ButtonApplySection/ButtonApplyContainer";

const EditProfileDesktop = ({
  dispatch,
  profileData,
  handlerSetDataProfile,

  action_tab,
  listSectionTabs,
  handlerChangeSection,
}) => {
  if (checkEmptyDataObject(profileData))
    return (
      <WrapContainerPreloader>
        Загрузка ... <Preloader />
        <Offset mb={30} />
      </WrapContainerPreloader>
    );
  const formId = useId();

  return (
    <WrapContainer isDesktop>
       <Offset mt={'var(--offset-top-desktop)'} />
      <BlockGrid addClass="profile-desktop__self-info">
        <HeaderTitleActionComponent list={["Изменить информацию"]} />
        <Offset mb={14} />
        <Formik
          initialValues={profileData}
          validationSchema={editProfile(messageErrorValidation)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            resetForm,
            handleSubmit,
            onSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => {
            useEffect(() => { dispatch(ACTION_SET_CONTROLL_BUTTON, { buttonForm: handleSubmit,formId });  }, [dispatch]);
            return (
              <Form
                id={formId}
                onSubmit={onSubmit}
                style={{
                  display: "grid",
                  gap: "25px 10px",
                  gridTemplateColumns: "1fr 1fr",
                  gridTemplateAreas: `'name organization'
                                      'city address'
                                      'phone link'
                                      'additional-info .'`,
                }}
              >
                <BlockGrid style={{ gridArea: "name" }}>
                  <Label style={{ fontWeight: 500 }}>Ваше имя</Label>
                  <Offset mb={14} />
                  <Input
                    value={values.name}
                    placeholder={"Введите имя пользователя"}
                    name={"name"}
                    height={50}
                    onChange={(e) => {
                      const value = e.target.value;
                      const key = e.target.name;
                      setFieldValue([key], value);
                      handlerSetDataProfile({ key: [key], value });
                    }}
                    onBlur={handleBlur}
                    helptext={touched?.name && errors?.name}
                    style={{
                      border:
                        touched?.name && errors?.name
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    iconRight={penGrey}
                    styleIconRight={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </BlockGrid>
                <BlockGrid style={{ gridArea: "organization" }}>
                  <Label style={{ fontWeight: 500 }}>
                    Название/вид организации
                  </Label>
                  <Offset mb={14} />
                  <Input
                    value={profileData.organization}
                    name={"organization"}
                    height={50}
                    placeholder={"Bavarian–motors.ru"}
                    onBlur={handleBlur}
                    helptext={touched?.organization && errors?.organization}
                    style={{
                      border:
                        touched?.organization && errors?.organization
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const key = e.target.name;
                      setFieldValue([key], value);
                      handlerSetDataProfile({ key: [key], value });
                    }}
                    iconRight={penGrey}
                    styleIconRight={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </BlockGrid>
                <BlockGrid style={{ gridArea: "city" }}>
                  <Label style={{ fontWeight: 500 }}>Ваш город</Label>
                  <Offset mb={14} />
                  <Input
                    value={profileData.city}
                    name={"city"}
                    height={50}
                    placeholder={"Астрахань"}
                    onBlur={handleBlur}
                    helptext={touched?.city && errors?.city}
                    style={{
                      border:
                        touched?.city && errors?.city
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const key = e.target.name;
                      setFieldValue([key], value);
                      handlerSetDataProfile({ key: [key], value });
                    }}
                    iconRight={penGrey}
                    styleIconRight={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </BlockGrid>

                <BlockGrid style={{ gridArea: "address" }}>
                  <Label style={{ fontWeight: 500 }}>Ваш адрес</Label>
                  <Offset mb={14} />
                  <Input
                    value={profileData.address}
                    name={"address"}
                    height={50}
                    placeholder={"Например, Ленина 23/а"}
                    onBlur={handleBlur}
                    helptext={touched?.address && errors?.address}
                    style={{
                      border:
                        touched?.address && errors?.address
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const key = e.target.name;
                      setFieldValue([key], value);
                      handlerSetDataProfile({ key: [key], value });
                    }}
                    iconRight={penGrey}
                    styleIconRight={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </BlockGrid>

                <BlockGrid style={{ gridArea: "phone" }}>
                  <Label style={{ fontWeight: 500 }}>Телефон</Label>
                  <Offset mb={14} />

                  <PhoneContainer>
                    <Phone
                      placeholder={"+7 (000) 000-00-00"}
                      value={values.phone}
                      className={"form-input-number-phone-marketplace"}
                      id={"phone"}
                      autoComplete={"off"}
                      style={{
                        height: 48,
                        width: "100%",
                        borderRadius: 8,
                        border:
                          touched?.phone && errors?.phone
                            ? "1px solid #ff0000"
                            : `1px solid var(--border-color)`,
                        borderWidth: 1,
                        color: `var(--placeholder-color)`,
                        padding: "10px 13px",
                        pointerEvents: "all",
                        position: "relative",
                      }}
                      name={"phone"}
                      onFocus={(e) => {
                        return autoFocusFormInput(e, dispatch);
                      }}
                      onBlur={(e) => {
                        removeFocusFormInput(e, dispatch);
                        return handleBlur(e);
                      }}
                      helptext={touched?.phone && errors?.phone}
                      stylehelptext={{
                        color: "var(--text-color-red)",
                      }}
                      rules={{ validate: isPossiblePhoneNumber }}
                      onChange={(phone) => {
                        setFieldValue("phone", phone);
                        handlerSetDataProfile({
                          key: "phone",
                          value: phone,
                        });
                      }}
                    ></Phone>
                    <Icon
                      image={penGrey}
                      width={14}
                      height={14}
                      style={{
                        position: "absolute",
                        right: 22,
                        top: 15,
                      }}
                    />
                    {touched?.phone && errors?.phone ? (
                      <ErrorMessage>{errors?.phone} </ErrorMessage>
                    ) : // : !isValidNumber ?
                    //   <ErrorMessage>{'Номер телефона недействителен'}</ErrorMessage>
                    null}
                  </PhoneContainer>
                </BlockGrid>

                <BlockGrid style={{ gridArea: "link" }}>
                  <Label style={{ fontWeight: 500 }}>
                    Ссылка на авито/дром
                  </Label>
                  <Offset mb={14} />
                  <Input
                    value={profileData.avito_prom}
                    placeholder={
                      "https://auto.drom.ru/moscow/honda/accord/53151019.html"
                    }
                    name={"avito_prom"}
                    height={50}
                    onChange={(e) => {
                      const value = e.target.value;
                      const key = e.target.name;
                      setFieldValue([key], value);
                      handlerSetDataProfile({ key: [key], value });
                    }}
                    iconRight={penGrey}
                    styleIconRight={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </BlockGrid>

                <BlockGrid style={{ gridArea: "additional-info" }}>
                  <Label style={{ fontWeight: 500 }}>
                    Дополнительная информация
                  </Label>
                  <Offset mb={14} />
                  <TextArea
                    className={"textarea-application"}
                    value={profileData.add_info}
                    name={"add_info"}
                    placeholder={`Быстрая доставка, качество`}
                    height={100}
                    id={`textarea-1`}
                    onChange={(e) => {
                      const value = e.target.value;
                      const key = e.target.name;
                      setFieldValue([key], value);
                      handlerSetDataProfile({ key: [key], value });
                    }}
                  />
                </BlockGrid>
              </Form>
            );
          }}
        </Formik>
          <Offset mt={30} />
          <ButtonApplyContainer isDesktop />
      </BlockGrid>
    </WrapContainer>
  );
};

export default EditProfileDesktop;
