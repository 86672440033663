import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react'
import WithRouter from '../../HOC/WithRouter'
import { cloud } from '../../images';
import RaitingAndReviewMain from './RaitingAndReviewMain';
import { openOnlyURl } from '../../helpers/helpers';
import { ID_TELEGRAM_USER, REITING_CREATE, REITING_FULL_INFO, ROOT, menuRaitingAndReview } from '../../helpers/config';
import { getLocaleStore, setLocaleStore } from '../../helpers/utils';
import { ACTION_GET_SEARCH_TEXT_REVIEW, ACTION_GET_TOP_REVIEW, ACTION_SET_SEARCH_TEXT_FILTER, ACTION_SHOW_MORE_TOP_REVIEW } from '../../store/raiting-review/raiting-review';
import { ACTION_SET_BUTTON_HEADER_ACTION } from '../../store/helpers/helpers-store';
import RaitingDesktop from '../../PagesDesktop/Raiting/RaitingDesktop';

class RaitingAndReviewMainComponent extends Component {
  state = {
    showDesktopButton: true,
    listSection: [
      {
        title: "Все пользователи",
        id: 0,
        type: "all",
        active: true,
      },
      {
        title: "В топе",
        id: 1,
        type: "top",
        active: false,
      },
    ],
    isLoadingList: !!!this.props.listRaitingAndReview?.count,
  };
  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Рейтинг и отзывы",
      pathBackButton: ROOT,
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () => this.handleChangeScreen({ path: REITING_CREATE }),
          iconLeft: cloud,
          styleIconsLeft: { height: 12 },
          title: "Оставить отзыв",
          className: "button__controll--red",
        },
      ],
    });

    this.props.dispatch(ACTION_GET_TOP_REVIEW);

    if (this.props.textSearchReview !== "") {
      this.props.dispatch(ACTION_GET_SEARCH_TEXT_REVIEW);
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.listRaitingAndReview !== this.props.listRaitingAndReview) {
      if (this.props.listRaitingAndReview?.next_page === "End") {
        this.setState((state) => ({
          ...state,
          isLoadingList: false,
          showDesktopButton: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoadingList: false,
        }));
      }
    }
  }
  componentWillUnmount() {
    // this.props.dispatch(ACTION_SET_SEARCH_TEXT_FILTER, "");
  }
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  timer = null;
  handlerTextSearch = (text, e, callback) => {
    this.setState({
      isLoadingList: true,
    });
    this.timer = setTimeout(() => {
      clearTimeout(this.timer);
      return this.setState({
        isLoadingList: false,
      });
    }, 3000);
    if (!!text) {
      this.props.dispatch(ACTION_GET_SEARCH_TEXT_REVIEW, { callback });
      return //this.props.dispatch(ACTION_GET_TOP_REVIEW, { callback });
    } else {
      return this.props.dispatch(ACTION_GET_TOP_REVIEW, { callback });
    }
  };

  handlerClick = (id) => {
    if (!`${id}`.includes("http")) {
      setLocaleStore(ID_TELEGRAM_USER, id);
      this.props.navigate(REITING_FULL_INFO + "/" + id, { state: { id } });
      return;
    }
    return openOnlyURl(id);
  };

  handlerChangePagination = ({ page }) => {
    return this.props.dispatch(ACTION_GET_TOP_REVIEW, { page });
  };

  handleShowMore = () => {
    return this.props.dispatch(ACTION_SHOW_MORE_TOP_REVIEW, {
      page: this.props.listRaitingAndReview.next_page,
    });
  };

  render() {
    if (this.props.isDesktop) {
      return (
        <RaitingDesktop
          showDesktopButton={this.state.showDesktopButton}
          listMenu={menuRaitingAndReview}
          handlerClick={this.handlerClick}
          isLoadingList={this.state.isLoadingList}
          handleShowMore={this.handleShowMore}
          handlerTextSearch={this.handlerTextSearch}
          textSearchReview={this.props.textSearchReview}
          handleChangeScreen={this.handleChangeScreen}
          listRaitingAndReview={this.props.listRaitingAndReview}
        />
      );
    }
    return (
      <RaitingAndReviewMain
        listMenu={menuRaitingAndReview}
        listScreen={this.state.listScreen}
        handlerClick={this.handlerClick}
        isLoadingList={this.state.isLoadingList}
        handlerTextSearch={this.handlerTextSearch}
        textSearchReview={this.props.textSearchReview}
        handleChangeScreen={this.handleChangeScreen}
        handlerChangeSection={this.handlerChangeSection}
        listRaitingAndReview={this.props.listRaitingAndReview}
        handlerChangePagination={this.handlerChangePagination}
      />
    );
  }
}

export default connectStoreon(
  'textSearchReview',
  'listRaitingAndReview',

  WithRouter(RaitingAndReviewMainComponent)
)