import { Form, Formik } from "formik";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Label from "../../../View/Label/Label";
import Offset from "../../../View/Offset";
import TextArea from "../../../View/TextArea/TextArea";
import {
  addFile,
  bascketRed,
  plusFile,
} from "../../../images";
import FormUploadImageV2 from "../../../View/UploadImage/FormUploadImageV2";
import { messageErrorValidation } from "../../../helpers/schemaValidations/messgeSchemaValidations";
import { aggrigateSchema } from "../../../helpers/schemaValidations/schemaValidate";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import WithTooltip from "../../../HOC/WithTooltip";
import restrictionLengthText from "../../../helpers/helpers";
import Input from "../../../View/Input/Input";
import Icon from "../../../View/Icon/Icon";
import WrapTwoColumnGrid from "../../../View/Blocks/WrapTwoColumnGrid";
import WrapRowGrid from "../../../View/Blocks/WrapRowGrid";
import React from "react";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";
import Button from "../../../View/Button/Button";

const UnitSpareRequestEdit = ({
  dispatch,
  isDesktop,
  saveValueStore,
  valuesUnitSpare,
  handlerDeleteImage,
  isAddToListSparePart,
  handleDeleteAutoPartFromList,
}) => {
  const initValues = {
    options_autoparts: valuesUnitSpare?.options_autoparts,
    oem: null,
    text: valuesUnitSpare.text,
    phone_number: valuesUnitSpare.phone_number,
    image: valuesUnitSpare.images,
    data_complectation: {
      body_type: [],
      transmission: [],
      engine_code: [],
      doors_count: [],
    },
  };
  return (
    <WrapContainer
      isDesktop={isDesktop}
      style={{
        height: isDesktop ? "max-content" : "auto",
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />

      <BlockGrid
        addClass={isDesktop ? "profile-desktop__self-info" : ""}
        style={{
          maxHeight: isDesktop ? `var(--height-field-request, 700px)` : "unset",
        }}
      >
        {!valuesUnitSpare?.id ? (
          <WrapContainerPreloader>
            Загрузка... <Preloader /> <Offset mb={30} />{" "}
          </WrapContainerPreloader>
        ) : (
          <Formik
            validationSchema={aggrigateSchema(messageErrorValidation)}
            initialValues={initValues}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
            }) => {
              function getWrapStyle(touched, errors, type) {
                return {
                  border:
                    touched[type] && errors[type] ? "1px solid #ff0000" : "",
                };
              }
                function getGridAutoparts(amount) {
                  let stringAP = "'autopart_1 autopart_1 autopart_1 autopart_1 autopart_1 autopart_1'";
                  if (amount > 1)
                    Array.from({ length: amount }, (_, i) => i + 1).map(el => stringAP += `\n'autopart_${el + 1} autopart_${el + 1} autopart_${el + 1} autopart_${el + 1} autopart_${el + 1} autopart_${el + 1}'`)
                  return stringAP;
                }
              return (
                <>
                  <Offset mt={15} />
                  <Form
                    data-type="wrap-input"
                    className={
                      isDesktop
                        ? `request-unit-spare-desktop__container${valuesUnitSpare?.classes_list &&
                          valuesUnitSpare.classes_list.length > 0
                          ? "-preview"
                          : ""
                        }`
                        : ""
                    }
                    style={{
                      gridTemplateAreas: valuesUnitSpare?.classes_list &&
                        valuesUnitSpare.classes_list.length > 0 ?
                        `'preview preview preview preview preview preview'
                        'year year brand brand model model'
                  'volume volume petrol petrol power power'
                  'body body engine engine . .'
                  ${getGridAutoparts(valuesUnitSpare.classes[0]?.autoparts.length ?? 1)}
                  'add add . . . .'
                  'phone phone phone . . .'
                  'what-find what-find what-find upload upload upload'
                  '. . . . . .'
                  '. . . . . .'
                  'info_1 info_1 info_1 info_1 info_1 info_1'
                  'info_2 info_2 info_2 info_2 info_2 info_2'`
                        : `'year year brand brand model model'
                  'volume volume petrol petrol power power'
                  'body body engine engine . .'
                  ${getGridAutoparts(valuesUnitSpare.classes[0]?.autoparts.length ?? 1)}
                  'add add . . . .'
                  'phone phone phone . . .'
                  'what-find what-find what-find upload upload upload'
                  '. . . . . .'
                  '. . . . . .'
                  'info_1 info_1 info_1 info_1 info_1 info_1'
                  'info_2 info_2 info_2 info_2 info_2 info_2'`
                    }}
                  >
                        {/* preview create items */}
                        <WrapRowGrid style={{ gridArea: "preview" }}>
                          {valuesUnitSpare?.classes_list &&
                            valuesUnitSpare.classes_list.length > 0 &&
                            valuesUnitSpare.classes_list.map((item, index) => (
                              <>
                                <WrapTwoColumnGrid
                                  key={index}
                                  style={{
                                    border: "1px solid",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "relative",
                                    marginBottom: 10,
                                  }}
                                >
                                  <Label
                                    style={{
                                      fontWeight: 700,
                                      paddingRight: "34px",
                                    }}
                                  >
                                    {item.name}
                                  </Label>
                                  <Label>{`Год ${item.year_start} - ${item.year_end}`}</Label>
                                  <Label>{`Объём двигателя ${item.engine_volume}`}</Label>
                                  <Label>{`Тип топлива ${item.petrol_type}`}</Label>
                                  <Label>{`Мощность ${item.engine_power}`}</Label>
                                  <Label>{`Тип привода ${item.drive_type}`}</Label>
                                  <Label>{`Тип кузова ${item.body_type}`}</Label>
                                  <Label>{`Трансмиссия ${item.transmission}`}</Label>
                                  <Label>{`Код двигателя ${item.engine_code}`}</Label>
                                  <Label>{`Количество дверей ${item.doors_count}`}</Label>
                                </WrapTwoColumnGrid>
                              </>
                            ))}
                        </WrapRowGrid>
                    <Offset mt={20} />
                    {!!valuesUnitSpare.classes.length && valuesUnitSpare.classes[0].autoparts.map((c, i) =>
                      <div
                        key={c?.id}
                        style={{
                          position: 'relative',
                          gridArea: "autopart_" + (i + 1),
                          gridTemplateAreas: `'spare spare spare oem oem oem'`,
                          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                          display: isDesktop ? 'grid' : '',
                          gap: 10,
                        }}
                      >
                        {!isDesktop && <Offset mt={10} />}
                        <ReactSelectComponent
                          key={isAddToListSparePart}
                          label={"Запчасть *"}
                          data={values.options_autoparts}
                          enabled={valuesUnitSpare.classes_list.length > 0}
                          isUpTop={isDesktop ? false : true}
                          name={"autopart"}
                          classNamePrefix={"request"}
                          clearValue={!true}
                          placeholder={values.options_autoparts.filter(oa => oa.id === c.id)[0]?.title ?? "Выберите запчасть"}
                          onChange={(value) => {
                            setFieldValue("autopart_id", value);
                            setFieldValue(
                              "autopart", values.options_autoparts.filter(
                                (el) => el.value === value
                              )[0].title
                            );
                            handleDeleteAutoPartFromList(c.id)
                            saveValueStore({
                              type: "autoparts-part",
                              response: [{
                                id: value,
                                oem: values?.oem ?? '',
                                name: values.options_autoparts.filter(
                                  (el) => el.value === value
                                )[0].title,
                              }],
                            });
                          }}
                          helptext={touched?.autopart && errors?.autopart}
                          styleWrap={{
                            gridArea: 'spare',
                            ...getWrapStyle(touched, errors, "autopart"),
                          }}

                        />
                        {
                          !!(c?.id) &&
                          <Icon
                            image={bascketRed}
                            onClick={() => {
                              setFieldValue("autopart_id", null);
                              setFieldValue("autopart", null);
                              setFieldValue("oem", null);
                              handleDeleteAutoPartFromList(valuesUnitSpare.classes[0].autoparts[i].id)
                            }
                            }
                            style={{
                              position: "absolute",
                              right: 0,
                              top: isDesktop ? 0 : 0,
                            }}
                          />
                        }
                        {!isDesktop && <Offset mt={10} />}
                        <Offset
                          style={{
                            gridArea: "oem",
                            maxWidth: "100%"
                          }}
                        >
                          <Input
                            className={"textarea-application"}
                            disabled={!(valuesUnitSpare.classes_list.length > 0)}
                            key={isAddToListSparePart}
                            placeholder={c.oem ?? `Введите OEM`}
                            height={44}
                            label={"OEM"}
                            styleLabel={{
                              fontWeight: 700,
                            }}
                            distationtop={200}
                            name={"oem"}
                            style={{
                              border:
                                touched?.oem && errors?.oem
                                  ? "1px solid #ff0000"
                                  : "",
                            }}
                            styleWrap={{
                              border:
                                touched?.oem && errors?.oem
                                  ? "1px solid #ff0000"
                                  : "1px solid var(--border-select-color)",
                            }}
                            helptext={touched?.oem && errors?.oem}
                            stylehelptext={{
                              color: "var(--text-color-red)",
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue("oem", value);
                              saveValueStore({
                                type: "autoparts-part-oem",
                                response: [{
                                  id: valuesUnitSpare.classes[0].autoparts[i].id,
                                  oem: value ?? '',
                                }],
                              });
                            }}
                          />
                        </Offset>
                      </div>
                    )}

                    {/* button add  spare parts */}
                    <>
                      <Offset mt={20} />

                      <Button
                        addClass={"button__edit-my-profile--full"}
                        disabled={!(valuesUnitSpare.classes_list.length > 0 && !!valuesUnitSpare.classes[0]?.autoparts?.length)}
                        style={{ gridArea: "add", color: !(valuesUnitSpare.classes_list.length > 0) && '#eeeeee' }}
                        onClick={() => {
                          setFieldValue("autopart_id", null);
                          setFieldValue("autopart", null);
                          setFieldValue("oem", null);
                          saveValueStore({
                            type: "autoparts-new-fild",
                          });
                        }
                        }
                      >
                        {'Добавить запчасть'}
                      </Button>
                    </>
                        
                      <>

                        <Offset
                          mt={10}
                          style={{ gridArea: "phone", maxWidth: "100%" }}
                        >
                          <Input
                            className={"textarea-application"}
                            value={values.phone_number}
                          placeholder={`📞 Введите телефон, если необходимо:`}
                            height={40}
                            label={"Телефон"}
                            styleLabel={{
                              fontWeight: 700,
                            }}
                            distationtop={200}
                            name={"phone_number"}
                            style={{
                              border:
                                touched?.oem && errors?.oem
                                  ? "1px solid #ff0000"
                                  : "",
                            }}
                            styleWrap={{
                              border:
                                touched?.phone_number && errors?.phone_number
                                  ? "1px solid #ff0000"
                                  : "1px solid var(--border-select-color)",
                            }}
                            helptext={
                              touched?.phone_number && errors?.phone_number
                            }
                            stylehelptext={{
                              color: "var(--text-color-red)",
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue("phone_number", value);
                              saveValueStore({
                                type: "phone_number",
                                response: value,
                              });
                            }}
                          />
                        </Offset>

                        <Offset mt={10} />

                        <div
                          className={
                            isDesktop ? "request-repair-desktop__wrap-text" : ""
                          }
                        >
                          <TextArea
                            className={"textarea-application"}
                            value={values.text}
                          placeholder={`Опишите ваш запрос
если необходимо дополнительное описание`}
                            height={90}
                            label={"Ваш запрос"}
                            styleLabel={{
                              fontWeight: 700,
                            }}
                            distationtop={200}
                            id={`textarea-1`}
                            onBlur={handleBlur}
                            name={"text"}
                            onChange={(e) => {
                              const value = e.target.value;
                              const restrict = restrictionLengthText(
                                value,
                                4096,
                                dispatch
                              );
                              if (!restrict) return;
                              setFieldValue("text", value);
                              saveValueStore({
                                type: "text",
                                response: value,
                              });
                            }}
                          />
                        </div>
                        <FormUploadImageV2
                          multiple
                          className={
                            "request-repair-desktop__wrap-upload-images"
                          }
                          image={addFile}
                          listImages={
                            isDesktop
                              ? [
                                  { url: plusFile, type: "fake-image" },
                                  ...values.image,
                                ]
                              : [...values.image]
                          }
                          values={values}
                          maxCountImage={8}
                          maxSizeImage={5242880} // 5242880 => 5mb
                          setFieldValue={setFieldValue}
                          uploadTypeName="image"
                          positionPreview={isDesktop ? "over" : "under"}
                          onChange={({ key, value, callback }) => {
                            saveValueStore({
                              type: key,
                              response: value,
                            });
                          }}
                          handlerDeleteImage={handlerDeleteImage}
                        />
                      </>
                    <Offset mt={30} />

                    {/* <Label style={{ fontWeight: 700 }}>Что вы ищете</Label> */}
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
        <Offset mt={30} />
        {isDesktop && <Offset mt={30} />}
        {isDesktop && <ButtonApplyContainer isDesktop={isDesktop} />}
      </BlockGrid>
    </WrapContainer>
  );
};
export default (WithTooltip(UnitSpareRequestEdit));