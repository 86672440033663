import { v4 as uuidv4 } from "uuid";
import { _INIT, ACTION_GET, ACTION_POST } from '../api-store/getpage';
import { API_GET_FILTER_V3, API_GET_FILTER_V4, API_SET_FILTER_V3, API_SET_FILTER_V4, DEFAULT_PAGE_SIZE_INCOMING_REQUEST } from "../../helpers/config";
import { checkListIsValueSelectFilters, handlerWarningInfoMessageResponse, serialaizersFullFilters, serialaizersFullFilters_v2, unicArrayFilters } from "../../helpers/helpers";
import { ACTION_OPEN_MODAL, ACTION_SET_CONTROLL_BUTTON } from "../helpers/helpers-store";
import { ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL } from "./filtersIncominRequest";
import { ACTION_SET_MESSAGE_ERROR } from "../raiting-review/raiting-review";

export const GET_FILTERS = uuidv4();
export const GET_SEARCH_FILTERS = uuidv4();
export const SET_FILTERS_SEARCH = uuidv4();
export const SET_TEXT_SEARCH_SERVICE_FILTER = uuidv4();
export const GET_SEARCH_RESPARE_FILTERS = uuidv4();
export const GET_FILTER_BRAND_SHOW_MORE = uuidv4();
export const SET_FILTERS = uuidv4();
export const SET_VALUE_FILTER_BRAND = uuidv4();
export const SET_FILTER_BRAND_NULL = uuidv4();

export const GET_FILTER_MODEL = uuidv4();

export const SET_VALUE_FILTER_FOR_SEND = uuidv4();
export const SEND_VALUE_FILTERS = uuidv4();

export const SET_LOADING_FILTER_BRAND = uuidv4();
export const CHECK_SELECT_FILTERS = uuidv4();


export const IncomingRequestStore = store => {
    store.on(_INIT, ()=>({isLoadingBrand: false}));
    store.on(SET_LOADING_FILTER_BRAND, (_, data) => ({isLoadingBrand: data }));

    store.on(SET_VALUE_FILTER_BRAND, ({ storeFilters }, data, { dispatch }) => {
      // меняем состаяние в выбраном элементе списка
      let newListCardsByType = {};
      const {list} = data;
          newListCardsByType = {
            ...storeFilters[list],
            results: storeFilters[list].results.reduce((acc, cur) => {
              let newCur = { ...cur };
              if (+data.id === cur.id) {
                newCur = {
                  ...newCur,
                  ["status_" + data.sub_type]: {
                    status: !data.checked,
                    can_change: newCur["status_" + data.sub_type].can_change,
                  },
                };
              }
              acc.push(newCur);
              return acc;
            }, []),
          };
      // dispatch(SET_FILTERS, newListCardsByType)
      // сохраняем в объект списков для отправки на бек
      dispatch(SET_VALUE_FILTER_FOR_SEND, {
        id: data.id,
        sub_type: data.sub_type,
        list,
        callback: data.callback,
      });
      return {
        storeFilters: { ...storeFilters,
        [list]: newListCardsByType }
    }
    });

      store.on(CHECK_SELECT_FILTERS,
        ({ listOfSelectedFilters }, data, { dispatch }) => {
          let isActiveButton = false;
         const {obj , list, type} = data;
          const checkList = checkListIsValueSelectFilters({
            ...listOfSelectedFilters,
            ...obj,
          });

          if (checkList) {
            isActiveButton = true;
          } else {
            isActiveButton = false;
          }
          dispatch(ACTION_SET_CONTROLL_BUTTON, {
            isActive: isActiveButton,
            action: () => {
              dispatch(SEND_VALUE_FILTERS, {
                type,
                list,
                callback: data.callback,
              });
            },
          });
        }
      );

      store.on(SET_VALUE_FILTER_FOR_SEND,({ listOfSelectedFilters }, data, { dispatch }) => {
          let newSelectList = {};
          let { list }= data;
          
          for (let key in listOfSelectedFilters) {
            if (key.includes(data.sub_type) && key.includes(list)) {
              if (listOfSelectedFilters[key].includes(+data.id)) {
                newSelectList = {
                  ...listOfSelectedFilters,
                  [key]: listOfSelectedFilters[key].filter((el) => +el !== +data.id),
                };
              } else {
                newSelectList = {
                  ...listOfSelectedFilters,
                  [key]: [...listOfSelectedFilters[key], +data.id],
                };
              }
            }
          }

          let type_page = data.sub_type;
          console.log({list})
          if (data.sub_type === "unit") type_page = "unit_spare";
          if (data.sub_type === "spare") type_page = "unit_spare";
          if (list === "countrys") type_page = "respair";
          if (list === "citys") type_page = "respair";

          // проверяем если id для тправки на червер и активируем кнопку
          dispatch(CHECK_SELECT_FILTERS, {obj:newSelectList, list, type: type_page,callback: data.callback});
          
          return { listOfSelectedFilters: newSelectList };
        }
      );

    store.on(SEND_VALUE_FILTERS,
      async ({ listOfSelectedFilters, storeFilters }, data, { dispatch }) => {
        // dispatch(ACTION_SET_TEXT_SEARCH_FILTER_SERVICE, { q: "" });
        const {
          type, // тип страницы unit_spare | respair | truck | transport
          list, // список фильтров brands | models | generations
        } = data;
        let actionGetList = GET_FILTERS;
        // if(type === 'countrys') actionGetList = API_GET_FILTER_V4;
        let url = API_SET_FILTER_V3;
        if (type === "unit_spare") url = API_SET_FILTER_V4;
        let page = storeFilters[list]?.current_page;
        let params = {
          url,
          dataRequst: (res) => {
            const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
            if (isWarning) return;
            // if (res?.info?.status !== undefined && res?.info?.status) {
            if (typeof data?.callback === "function") data.callback(res, list);
            const getAction = (actionGetList, params) => {
              if (params.page > page ) return;
              if (list === 'citys' ) return; // делаем заглушку что бы не летел запрос на городах
              dispatch(actionGetList, params);
            };
            let options = {
              show: res?.info?.status,
              content: res?.info?.message,
              contentBtn: "Ок",
              error: !res?.info?.status,
              actionClickOk: () => {
                let params = {};
                if (
                  list === "models" ||
                  list === "generations" ||
                  list === "countrys" ||
                  list === "complectations" ||
                  list === "citys"
                ) {
                  params = {
                    type,
                    list,
                    page_size:
                      type === "respair"
                        ? 99999
                        : DEFAULT_PAGE_SIZE_INCOMING_REQUEST,
                    ...data,
                  };
                } else {
                  params = {
                    type,
                    list,
                    callback,
                    page_size:
                      type === "respair"
                        ? 99999
                        : DEFAULT_PAGE_SIZE_INCOMING_REQUEST,
                    ...data,
                  };
                }
                // что бы загрузить все страницы и обнавить данные будем рекурсивно вызывать с первой страницы до последней
                function callback(res) {
                  console.log("%cwork callback", "color: red");
                  params = { ...params, page: res.current_page + 1 };
                  getAction(actionGetList, params);
                }
                params = { ...params, page: 1 };
                getAction(actionGetList, params);
              },
            };
            if (data?.path) options = { ...options, path: data?.path };
            dispatch(ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL);
            dispatch(ACTION_OPEN_MODAL, options);
            return true;
            // } else {
            //   console.log("%cget second if", "color: green");
            //   dispatch(actionGetList, {
            //     type,
            //     page,
            //     list,
            //   });
            // }
          },
          ...listOfSelectedFilters,
          ...data,
        };

        dispatch(ACTION_POST, params);
      }
    );

    const initIrfBrand = {
      brands: {
        count: 0,
        results: [],
      },
      models: {
        count: 0,
        results: [],
      },
      generations: {
        count: 0,
        results: [],
      },
      complectations: {
        count: 0,
        results: [],
      },
      countrys: {
        count: 0,
        results: [],
      },
      citys: {
        count: 0,
        results: [],
      },
    };
    store.on(_INIT, ()=>({storeFilters: initIrfBrand}));

    store.on(GET_FILTERS, (_, data, {dispatch}) => { 
        const {list, type} = data;
        dispatch(SET_LOADING_FILTER_BRAND, true);
        let url = API_GET_FILTER_V3;
        if (type === "unit_spare") url = API_GET_FILTER_V4;
        let params = {
          ...data,
          type, // "unit_spare",
          url,
          list,
          blockButtonDisabled: true,
          disabledFetchLoader: true,
          page_size: data?.page_size ?? DEFAULT_PAGE_SIZE_INCOMING_REQUEST,
          dataRequst: (res) => {
            console.log({res})
            if(res === undefined) return;
            if (typeof data?.callback === "function") data.callback(res);
            dispatch(SET_LOADING_FILTER_BRAND, false);
            dispatch(SET_FILTERS, {
                count: res.count,
                results: res.results,
                current_page: res.current_page,
                count_pages: res.count_pages,
                list
            });
          },
        };
        dispatch(ACTION_GET, params);
    });

    store.on(GET_FILTER_BRAND_SHOW_MORE, ({ storeFilters }, data, {dispatch}) => {
      let page = storeFilters?.brands?.current_page + 1;
      const {list, type} = data;
      let params = {
        page,
        list,
        type,
      };
      dispatch(GET_FILTERS, params);
    });

    store.on(SET_FILTERS,({ storeFilters, listOfSelectedFilters }, data, { dispatch }) => {
      const { list } = data;
      try {
        let newValues = {
          ...storeFilters[list],
          ...data,
          results: unicArrayFilters([
            ...data.results,
            // ...storeFilters[list].results
          ]),
        };
        console.log({ newValues });
        const response = {
          storeFilters: {
            ...storeFilters,
            [list]: serialaizersFullFilters_v2(
              newValues,
              listOfSelectedFilters,
              list
            ),
          },
        };
        return response;
      } catch (error) {
        console.log(error);
      }
    }
    );
    store.on(SET_FILTERS_SEARCH,({ storeFilters, listOfSelectedFilters }, data, { dispatch }) => {
        const {list} = data;
        const response = {
          storeFilters: {
            ...storeFilters,
            [list]: data,
          },
        };
    if (!response.storeFilters[list].results.length)
      dispatch(ACTION_SET_MESSAGE_ERROR, "Нет совподений");
        return response;
      }
    );

    store.on(SET_FILTER_BRAND_NULL, (_, data, { dispatch }) => {
    //   dispatch(SET_FILTERS, initIrfBrand);
    });

    let controllerAbortSearchFilter = null;
    store.on(GET_SEARCH_FILTERS,
      ({ storeFilters, textSearchIncominFilter },
        data,
        { dispatch }
      ) => {
        controllerAbortSearchFilter &&
          controllerAbortSearchFilter.abort();
        controllerAbortSearchFilter = new AbortController();
        const { url, text, type, list, callback } = data;
        if (
          typeof textSearchIncominFilter === "string" &&
          textSearchIncominFilter.length !== 0
        ) {
          let params = {
            url,
            type,
            list,
            q: textSearchIncominFilter,
            page_size: 9999,
            callback,
            abortController: controllerAbortSearchFilter,
            dataRequst: (res) => {
              if (res === undefined) return;
              const isWarning = handlerWarningInfoMessageResponse(
                res,
                dispatch
              );
              if (typeof callback === "function") callback();
              dispatch(SET_FILTERS_SEARCH, { ...res, list });
            },
          };
          dispatch(ACTION_GET, params);
        } else {
          let params = {
            type: "unit_spare",
            list: 'brands',
            page: 1,
          };
          dispatch(GET_FILTERS, params);       
          if (typeof callback === "function") callback();
        }
      }
    );
    
    let tempFilterRespare = {
        countrys: {
            count: 0,
            results: []
        },
        citys: {
            count: 0,
            results: []
        }
    }

    let idCountryTemp =  null
    store.on(GET_SEARCH_RESPARE_FILTERS,
      ({ storeFilters, textSearchIncominFilterService }, data, { dispatch }) => {
        const { list, callback, country_id } = data;
        if(typeof callback === 'function') callback();
        if (country_id === null) return dispatch(ACTION_SET_MESSAGE_ERROR, "Нет выбран город");

        if (country_id !== idCountryTemp) {
            idCountryTemp = country_id;
          tempFilterRespare = {
            ...tempFilterRespare,
            [list]: storeFilters[list],
          };
        } 
        if (textSearchIncominFilterService === ''){
            return {
              storeFilters: {
                ...storeFilters,
                [list]: {
                  ...storeFilters[list],
                  results: unicArrayFilters([...tempFilterRespare[list].results]),
                },
              },
            };
        }
        const res = {
            [list]: {
              ...storeFilters[list],
              results: unicArrayFilters([
                ...tempFilterRespare[list].results.filter(
                  (el) =>
                    el.name.toUpperCase().includes(textSearchIncominFilterService.toUpperCase())
                ),
              ]),
          },
        };
        if(!res[list].results.length) dispatch(ACTION_SET_MESSAGE_ERROR, 'Нет совподений');
        return dispatch(SET_FILTERS_SEARCH, { ...res[list], list });
        
        
        }
    );

    store.on(SET_TEXT_SEARCH_SERVICE_FILTER,
      ({ tempIncominFilterService, callbackSearch }, data, { dispatch }) => {
        if (typeof callbackSearch === "function") callbackSearch();
        if (typeof data.callback === "function")
          data.callback();
        return { textSearchIncominFilterService: data?.q };
      }
    );
}