import RecomandationsMarketplaceContainer from "../../Components/RecomandationsMarketplace/RecomandationsMarketplaceContainer";
import Menu from "../../View/Menu/Menu";
import Offset from "../../View/Offset";
import WrapContainer from "../../View/WrapContainer/WrapContainer";

const MenuCarDisassembled = ({ handleChangeScreen, listMenu, isDesktop }) => {
  return (
    <WrapContainer isDesktop={isDesktop}>
      <Menu
        isDesktop={isDesktop}
        list={listMenu}
        handlerScreen={handleChangeScreen}
        iconSize={18}
      />
      <Offset mb={17} />
      <RecomandationsMarketplaceContainer slidesPerView={isDesktop? 6 : 2}/>
      <Offset mt={15} />
    </WrapContainer>
  );
};
export default (MenuCarDisassembled);
