import React, { Component } from 'react'
import HeaderDesktop from './HeaderDesktop'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../HOC/WithRouter';
import { bell, userWhite } from '../../images';
import {  NOTIFICATION, PROFILE_MENU } from '../../helpers/config';
import { ACTION_GET_DATA_INFO_PROFILE } from '../../store/profile/profile';

export class HeaderDesktopContainer extends Component {
  state = {
    buttons: [
      {
        id: 0,
        action: () => this.handleChangeScreen({ path: NOTIFICATION }),
        iconLeft: bell,
        styleIconsLeft: { height: 16 },
        title: "",
        className: "button__header-desktop-bell",
      },
      {
        id: 1,
        action: () => this.handleChangeScreen({ path: PROFILE_MENU }),
        iconLeft: userWhite,
        styleIconsLeft: { height: 14 },
        style: { marginLeft: 10 },
        title: "",
        className: "button__header-desktop-profile",
      },
    ],
  };

  componentDidMount() {
    this.props.dispatch(ACTION_GET_DATA_INFO_PROFILE);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.profileInfoData !== this.props?.profileInfoData) {
      this.setState((state) => ({
        ...state,
        buttons: state.buttons.map((btn) =>
          btn.id === 1
            ? { ...btn, title: this.props.profileInfoData.user }
            : btn
        ),
      }));
    }
  }

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  closeApp = () => {
    if(this.props.tg.isFullscreen){
      document.documentElement.style.setProperty(
        "--height-field-request",
        `calc(${this.props.tg.viewportHeight}px - 130px)`
      );
this.props.tg.exitFullscreen();
    }else{
this.props.tg.requestFullscreen();
    }
  }

  render() {
    return (
      <HeaderDesktop
        buttonsHeader={{ buttons: this.state.buttons }}
        closeApp={this.closeApp}
      />
    );
  }
}

export default connectStoreon( 
  'profileInfoData',
  'tg',
  WithRouter( HeaderDesktopContainer)
)