import React, { Component } from 'react'
import IncomingRequestUnitSpareDesktop from './IncomingRequestUnitSpareDesktop'
import { connectStoreon } from 'storeon/react';
import { API_SEARCH_FILTER } from '../../../helpers/config';
import { ACTION_SET_CONTROLL_BUTTON } from '../../../store/helpers/helpers-store';
import { CHECK_SELECT_FILTERS, GET_FILTERS, GET_FILTER_BRAND_SHOW_MORE, GET_SEARCH_FILTERS, SET_VALUE_FILTER_BRAND } from '../../../store/filters/IncomingRequestStore';
import { ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL } from '../../../store/filters/filtersIncominRequest';

export class IncomingRequestUnitSpareDesktopContainer extends Component {
  state = {
    showDesktopButton: false,
  };
  componentDidMount() {
    if (!this.props.storeFilters?.brands?.results?.length) {
      this.props.dispatch(GET_FILTERS, {
        page: 1,
        list: "brands",
        type: "unit_spare",
        callback: () => {},
      });
    } else {
      if (
        this.props.storeFilters?.brands?.current_page <
        this.props.storeFilters?.brands?.count_pages
      ) {
        this.setState((state) => ({
          ...state,
          showDesktopButton: true,
        }));
      }
    }
    if (this.props.isDesktop)
      setTimeout(() => {
        this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
          isActive: false,
          isFetch: false,
          isfixed: true,
          typeButton: "fixed",
          show: true,
          addClass: "button__apply--incomin-request-red",
          title: "Применить",
        });
        this.props.dispatch(CHECK_SELECT_FILTERS, {
          obj: {},
          list: "brands",
          type: "unit_spare",
        });
      }, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.storeFilters !== this.props.storeFilters) {
      if (!this.props.storeFilters?.brands?.count) {
        this.setState((state) => ({
          ...state,
          showDesktopButton: false,
        }));
      } else if (
        this.props.storeFilters?.brands?.current_page ===
        this.props.storeFilters?.brands?.count_pages
      ) {
        this.setState((state) => ({
          ...state,
          showDesktopButton: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showDesktopButton: true,
        }));
      }
    }
  }

  componentWillUnmount() {
    // this.props.dispatch(SET_FILTER_BRAND_NULL);

    // setSessionStore("currentPageFilters", 1);
    if (this.props.isDesktop)
      setTimeout(() => {
        this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
          isActive: false,
          isFetch: false,
          typeButton: "fixed",
          show: false,
          addClass: "button__apply--incomin-request-red",
          title: "Применить",
        });
      }, 0);
    // this.props.dispatch(ACTION_SET_LIST_CARDS_BY_TYPE_FILTER_NULL);
    this.props.dispatch(ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL);
  }
  handlerTextSearch = (text, e, callback) => {
    this.props.dispatch(GET_SEARCH_FILTERS, {
      list: "brands",
      type: "brand",
      url: API_SEARCH_FILTER,
      text,
      callback,
    });
  };

  fetchDataModels = ({ brand_id }) => {
    // setLocaleStore("brands", brand_id);
    this.props.dispatch(GET_FILTERS, {
      type: "unit_spare",
      list: "models",
      page_size: 99999,
      page: 1,
      brand_id,
      callback: this.callback,
    });
  };
  fetchDataGeneration = ({ model_id, listsModels }) => {
    // setLocaleStore("models", model_id);
    this.props.dispatch(GET_FILTERS, {
      type: "unit_spare",
      list: "generations",
      page_size: 99999,
      model_id,
      page: 1,
      callback: this.callback,
    });
  };

  fetchDataСomplectations = ({ generation_id }) => {
    // setLocaleStore("models", model_id);
    this.props.dispatch(GET_FILTERS, {
      type: "unit_spare",
      list: "complectations",
      page_size: 99999,
      generation_id,
      page: 1,
      callback: this.callback,
    });
  };

  // Обработчик изменения выбора
  // handleChangeModel = (selected, sub_type) => {
  //   this.handlerSelectItemFilter({
  //     id: selected.value,
  //     sub_type,
  //     type: "models",
  //     checked: true,
  //     curTab: 1,
  //   });
  // };

  handlerSelectItemFilter = ({
    id,
    sub_type,
    list,
    checked,
    curTab,
    generation_id,
  }) => {
    this.props.dispatch(SET_VALUE_FILTER_BRAND, {
      id,
      sub_type,
      list,
      checked,
      generation_id,
    });
  };

  handleShowMore = () =>
    this.props.dispatch(GET_FILTER_BRAND_SHOW_MORE, {
      list: "brands",
      type: "unit_spare",
    });

  render() {
    return (
      <IncomingRequestUnitSpareDesktop
        handleShowMore={this.handleShowMore}
        showDesktopButton={this.state.showDesktopButton}
        fetchDataModels={this.fetchDataModels}
        fetchDataGeneration={this.fetchDataGeneration}
        fetchDataСomplectations={this.fetchDataСomplectations}
        resSelectCard={this.props.resSelectCard}
        textInputSearch={this.props.textSearchIncominFilter}
        handlerTextSearch={this.handlerTextSearch}
        handlerSelectItemFilter={this.handlerSelectItemFilter}
        resultBrands={this.props.storeFilters.brands}
        resultModels={this.props.storeFilters.models?.results ?? []}
        resultGenerations={this.props.storeFilters.generations?.results ?? []}
        resulСomplectations={
          this.props.storeFilters.complectations?.results ?? []
        }
      />
    );
  }
}

export default connectStoreon(
  "storeFilters",
  "isLoadingBrand",

  "resSelectCard",
  "listCardsByType",
  "controllButton",
  "listOfSelectedFilters",

  "textSearchIncominFilter",

  IncomingRequestUnitSpareDesktopContainer
);