import React from "react";
import CardMPItem from "../../View/Cards/CardMPItem";
import { WithFavorite } from "../../HOC/WithFavorite";
import { UPDATE_DATA_RECOMANDATIONS } from "../../store/marketplace/recomandation/recomandation";
import Offset from "../../View/Offset";
import HeaderTitleActionComponent from "../Component.HeaderTitleAction/HeaderTitleActionComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination } from "swiper";

import "swiper/scss";
import "swiper/scss/effect-fade";

const RecomandationsMarketplace = ({
  dispatch,
  slidesPerView=2,
  recomandations,
  toggleFavorite,
}) => {

  if (!recomandations?.count) return null;
  return (
    <>
      <Offset mt={20} />
      <HeaderTitleActionComponent list={["Рекомендованные"]} />
      <Offset mt={10} />
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={15}
        className="recom-bullet"
        loop="true"
        autoplay="true"
        effect="fade"
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        style={{
          // width: "calc(100% - 20px)",
          minHeight: 380,
        }}
      >
        {recomandations.results.map((item, i) => {
          return (
            <SwiperSlide
              key={`recom-${i}`}
              style={{
                width: 150,
                // maxWidth: "calc((100vw / 2) - 10px)",
                display: "block",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <CardMPItem
                item={item}
                style={{width: 'auto'}}
                dispatch={dispatch}
                hendlerFavorite={(e) => {
                  toggleFavorite(e);
                  dispatch(UPDATE_DATA_RECOMANDATIONS);
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* </ColumnGrid> */}
    </>
  );
};

export default WithFavorite(RecomandationsMarketplace);
