import React, { Component } from 'react';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import BlockFlex from '../../../View/Blocks/BlockFlex';
import ItemAccordionIcon from '../../../View/Accordion/ItemAccordionIcon';
import ItemAccordionContainerTitle from '../../../View/Accordion/ItemAccordionContainerTitle';
import TextTitle from '../../../View/Text/TextTitle';
import ItemAccordionDescription from '../../../View/Accordion/ItemAccordionDescription';

class FAQContainer extends Component {
  state = {
    listOpenDesc: [],
  };

  componentDidMount() {
    const listItems = this.props?.list;
    if (listItems && listItems?.length > 0) {
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i]?.isActive) {
          this.setState((state) => ({
            ...state,
            listOpenDesc: [i],
          }));
        }
      }
    }
  }
  handlerOpen = (even) => {
    const elementAccordion = +even.target.getAttribute("data_desc");
    if (this.state.listOpenDesc.includes(+elementAccordion)) {
      this.setState({
        listOpenDesc: [],
      });
      // this.props.onChange(elementAccordion, "hide");
    } else {
      this.setState({
        listOpenDesc: [].concat(+elementAccordion),
      });
      // this.props.onChange(elementAccordion, "show");
    }
  };

  render() {
    return (
      <BlockGrid addClass='block__main-desktop-container-faq'>
        {this.props.list.map((item, i) => {
          return (
            <BlockFlex
              addClass="block__main-desktop-container-faq-item"
              key={item.id}
              data_desc={i}
              onClick={this.handlerOpen}
              style={{
                ...(this.props?.styleItem ?? {}),
                width: item?.isLittleBlock && !item?.isActive ? 170 : "100%",
                // height: this.state.listOpenDesc.includes(i)? 'auto' : 0,
                // paddingTop: this.state.listOpenDesc.includes(i)? 14 : 0,
                // paddingBottom: this.state.listOpenDesc.includes(i)? 14 : 0,
              }}
            >
              <ItemAccordionContainerTitle>
                <TextTitle addClass='text__main-desktop-faq-title-item'>{item.title}</TextTitle>
                <ItemAccordionIcon
                  // isShow={this.state.isShowDescription}
                  data_desc={i}
                  isOpen={this.state.listOpenDesc.includes(i)}
                  image={item.icon}
                  styleItemIcon={{
                    ...(this.props?.styleItemIcon ?? {}),
                  }}
                  // accord={i}
                />
              </ItemAccordionContainerTitle>
              <ItemAccordionDescription
                // isOpen={+this.state.isShowDescription.accord === i}

                isOpen={this.state.listOpenDesc.includes(i)}
              >
                {item.description}
              </ItemAccordionDescription>
            </BlockFlex>
          );
        })}
      </BlockGrid>
    );
  }
}

export default FAQContainer;