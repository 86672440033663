import React from 'react'
import NavigationMyApplication from '../../../View/Navigation/NavigationMyApplication/NavigationMyApplication'
import CardApplication from '../../../View/Cards/CardApplication'
import Offset from '../../../View/Offset'
import WrapContainer from '../../../View/WrapContainer/WrapContainer';

export default function OwnRequest({
  isDesktop,
  listSection,
  listMyApplication,
  handlerChangeSection,
  handlerActionMyApplication,
}) {
  return (
    <WrapContainer isDesktop={isDesktop}>
      <Offset mt={10} />
      <NavigationMyApplication
        isDesktop={isDesktop}
        listSection={listSection}
        handlerChangeSection={handlerChangeSection}
      />

      <Offset mt={14} />
      <CardApplication
        isDesktop={isDesktop}
        list={listMyApplication}
        handlerActionMyApplication={handlerActionMyApplication}
      />
    </WrapContainer>
  );
}
