import React, { useId } from "react";
import Button from "../Button/Button";
import WrapContainer from "../WrapContainer/WrapContainer";
import { loader, settingRedRotation } from "../../images";
import Icon from "../Icon/Icon";
import TooltipComponent from "../../Components/Component.Tooltip/TooltipComponent";
import WithTooltip from "../../HOC/WithTooltip";


const ButtonApplyStatic = ({
  icon,
  show,
  type,
  title,
  formId,
  tooltip,
  isFocus,
  isFetch,
  onClick,
  isActive,
  addClass,
  isDesktop,
  buttonForm,
  handlerShowTooltip,
}) => {
  const id = useId();
  let styleDesktop = {};
  if(isDesktop){
    styleDesktop = {
      ...styleDesktop,
      maxWidth: '100%'
    }
  }
  return (
    <WrapContainer
      style={{
        zIndex: "var(--z-index-wrap)",
        ...styleDesktop
      }}
    >
      {isFocus ? null : show ? (
        <>
          <TooltipComponent
            onClick={
              !isActive
                ? () => {
                    buttonForm();
                    handlerShowTooltip({
                      key: "validate",
                      id: id,
                      action: "validate",
                    });
                  }
                : null
            }
            style={{ bottom: -10, left: -10 }}
            message={tooltip?.validate?.validate?.message}
            isShow={
              tooltip?.validate?.validate && tooltip?.validate?.validate?.isShow
            }
          >
            <Button
              id={id}
              style={{ width: "100%" }}
              disabled={!isActive}
              type={type}
              addClass={addClass}
              onClick={onClick}
              form={formId}
            >
              {title}
              {isFetch ? (
                <Icon
                  // image={settingRedRotation}
                  id={'static-apply'}
                  image={loader}
                  width={20}
                  height={20}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                  }}
                />
              ) : null}
            </Button>
          </TooltipComponent>
        </>
      ) : null}
    </WrapContainer>
  );
}

export default WithTooltip(ButtonApplyStatic);