import React from 'react'
import WrapContainer from '../../../View/WrapContainer/WrapContainer'
import Offset from '../../../View/Offset';
import Label from '../../../View/Label/Label';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import HeaderTitleActionComponent from '../../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent';
import SearchSectionWithOfferContainer from '../../../Components/SearchSectionWithOffer/SearchSectionWithOfferContainer';
import Select from "react-select";
import { getDataInfoWhereWorkUser, getDataInfoWhereWorkUserDesktop, serializerOptions, serializerOptionsCities } from '../../../helpers/helpers';
import WrapTitleDescBlockOpacity from '../../../View/Blocks/WrapTitleDescBlockOpacity';
import RaitingContainer from '../../../View/Raiting/RaitingContainer';
import Button from '../../../View/Button/Button';
import { arrowDown, cross, crossWhite } from '../../../images';
import CardTitleDescBlock from '../../../View/Cards/Detail/review/CardTitleDescBlock';
import CardDescBlock from '../../../View/Cards/Detail/review/CardDescBlock';
import BlockFlex from '../../../View/Blocks/BlockFlex';
import ButtonDesktop from '../../../View/ButtonDesktop/ButtonDesktop';

const WarrantMemberDesktop = ({
  isLoading,
  selectCity,
  listCities,
  showFullInfo,
  handlerSelect,
  isLoadingCards,
  handleShowMore,
  changePagination,
  showDesktopButton,
  handleShowFullInfo,
  handleChangeScreen,
  listCardsForWarrantlyMemners,
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <Offset mt={6} />
        {/* Гаранты–участники */}
        <HeaderTitleActionComponent list={["Гаранты–участники"]} />

        <Offset mt={26} />
        <Label style={{ fontWeight: 700, letterSpacing: 0.1 }}>Ваш город</Label>
        <Offset mt={6} />
        {/* search */}
        <BlockGrid style={{ gridTemplateColumns: "1fr 1fr" }}>
          <Select
            options={serializerOptionsCities(listCities)}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            onChange={({ value }) => handlerSelect(value)}
            isLoading={isLoading} // Показывать индикатор загрузки
            placeholder="Выберите город"
            isSearchable // Включение поиска
            noOptionsMessage={() => "Города с таким названием нет в списке"}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 99999, // Чтобы выпадающий список не скрывался другими элементами
              }),
            }}
          />
        </BlockGrid>
        <Offset mt={6} />
        <Label
          style={{
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: "0px",
          }}
        >
          Всего гарантов: {listCardsForWarrantlyMemners.count}
        </Label>
        <Offset mt={16} />
        {/* catalog */}
        {listCardsForWarrantlyMemners?.results &&
          listCardsForWarrantlyMemners.results.map((item, index) => {
            return (
              <BlockGrid
                style={{
                  gridTemplateColumns: "1.5fr 1.5fr 0.4fr 1.5fr ",
                  gridTemplateAreas: `'user company review controller'
                  'desc desc desc desc'`,
                  gap: 20,
                  alignItems: "center",
                }}
              >
                {/* user */}
                <BlockGrid
                  onClick={() =>
                    handleChangeScreen({
                      id: item?.user_data?.user_telegram_id,
                      username: item.username,
                    })
                  }
                  style={{
                    gridArea: "user",
                  }}
                >
                  <Label style={{ color: "blue" }}>
                    {item.user_data.user_profile_name}
                  </Label>
                </BlockGrid>
                {/* company */}
                <BlockGrid
                  style={{
                    gridArea: "company",
                    // backgroundColor: "green",
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    // gridTemplateColumns: '1fr 1fr'
                  }}
                >
                  {/* <Label>Company</Label> */}
                  {/* <Offset mb={10} /> */}
                  <WrapTitleDescBlockOpacity>
                    Компания:
                  </WrapTitleDescBlockOpacity>
                  <Label style={{ fontWeight: 700 }}>
                    {item.seller_organization}
                  </Label>
                </BlockGrid>
                {/* review */}
                <BlockGrid
                  style={{
                    gridArea: "review",
                    // backgroundColor: "blue",
                  }}
                >
                  {/* <Label>Review</Label> */}
                  {/* <Offset mb={10} /> */}
                  <RaitingContainer
                    max={5}
                    value={item?.rating_int}
                    sizeStarHeight={17}
                    sizeStarWidth={17}
                    reverse
                    backgroundFone
                  />
                </BlockGrid>
                {/* controller */}
                <BlockGrid
                  style={{
                    gridArea: "controller",
                    justifySelf: "end",
                    // backgroundColor: "blue",
                    gridTemplateColumns: "1fr 1fr ",
                    gap: 10,
                  }}
                >
                  <Button
                    addClass={
                      showFullInfo === item.id
                        ? "button__apply-dark-blue"
                        : "button__apply-light-blue"
                    }
                    onClick={() => handleShowFullInfo(item.id)}
                    iconRight={
                      showFullInfo === item.id ? crossWhite : arrowDown
                    }
                    styleIconsRight={{
                      width: 6,
                      height: 6,
                    }}
                  >
                    {showFullInfo === item.id ? "Закрыть" : "Смотреть"}
                  </Button>
                  <Button
                    addClass={"button__apply-red"}
                    onClick={() =>
                      handleChangeScreen({ url: item?.user_data?.user_url })
                    }
                  >
                    Написать продавцу
                  </Button>
                </BlockGrid>
                {showFullInfo === item.id ? (
                  <BlockFlex
                    style={{
                      gridArea: "desc",
                      flexDirection: "column",
                      backgroundColor: "#F7F8F9",
                      borderRadius: 16,
                    }}
                  >
                    <Label style={{ fontWeight: 700, letterSpacing: 0.1 }}>
                      Инфо об продавце:
                    </Label>
                    <Offset mb={10} />

                    <CardTitleDescBlock style={{ fontSize: 14 }}>
                      {item.seller_address}
                    </CardTitleDescBlock>
                    <Offset mb={10} />
                    <CardTitleDescBlock style={{ fontSize: 14 }}>
                      {item.seller_comment}
                    </CardTitleDescBlock>

                    <Offset mb={10} />
                    <CardTitleDescBlock style={{ fontSize: 14 }}>
                      Работал с:
                      <span
                        style={{
                          color: "var(--text-color-opacity)",
                          display: " flex",
                          flexDirection: "column",
                        }}
                        dangerouslySetInnerHTML={{
                          a: (provider) => {},
                          __html: getDataInfoWhereWorkUserDesktop(
                            item.seller_worked
                          ),
                        }}
                      ></span>
                      <Offset mb={10} />
                      {/* {getDataInfoWhereWorkUser(item.seller_worked)} */}
                    </CardTitleDescBlock>
                  </BlockFlex>
                ) : null}
                <Offset mt={8} />
              </BlockGrid>
            );
          })}
        {showDesktopButton && <Offset mt={30} />}

        <ButtonDesktop
          show={showDesktopButton}
          isActive={showDesktopButton}
          type={"button"}
          name={"Загрузить еще"}
          addClass={"button__controll-roze--full"}
          onClick={handleShowMore}
        />
      </BlockGrid>
    </WrapContainer>
  );
};

export default WarrantMemberDesktop;