import React, { PureComponent } from 'react'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../../HOC/WithRouter';
import MainCatalogMarketPlace from './MainCatalogMarketPlace';
import { getSessionStore, setLocaleStore, setSessionStore } from '../../../helpers/utils';
import { ACTION_GET_LIST_CARDS_MARKET, ACTION_GET_LIST_MY_CARDS_MARKET, ACTION_SHOW_MORE_LIST_CARDS_MARKET } from '../../../store/marketplace/marketplace';
import { funcDelay } from '../../../helpers/const';
import CatalogComponentDesktop from '../../../Components/Desktop/Marketplace/CatalogComponentDesktop';
import { ACTION_GET_LIST_OPTIONS_MARKET_CATEGORY, ACTION_LIST_OPTIONS_MARKET, ACTION_RESET_FILTER_INTO_MARKET, ACTION_SET_FILTER_INTO_MARKET } from '../../../store/marketplace/filtermarketplace/filtermarketplace';
import { openOnlyURl } from '../../../helpers/helpers';
import { DEFAULT_PAGE_SIZE_MARKET } from '../../../helpers/config';

class MainCatalogMPContainer extends PureComponent {
  state = {
    showDesktopButton: true,
    isLoading: true,
    isLoadingPagination: false,
    showParams: true,
  };

  componentDidMount() {
    const getData = async () => {
      setLocaleStore("lastPathForFiltersMarket", this.props.pathname);
      document.documentElement.style.setProperty("--placeholder-size", "14px");
      setLocaleStore("lastPathForDetailCardMarket", this.props.pathname);
      this.props.dispatch(ACTION_GET_LIST_MY_CARDS_MARKET, {
        filter_by_favorite: true,
      });
      // let params = { callback: this.callback };
      this.setState((state) => ({
        ...state,
        isLoadingPagination: true,
      }));
      let params = { callback: this.callbackPaggination };
      this.props.dispatch(ACTION_GET_LIST_CARDS_MARKET, params);
      this.props.dispatch(ACTION_GET_LIST_OPTIONS_MARKET_CATEGORY);
      this.getOptionsDropDownList("countrys");
      this.getOptionsDropDownList("brands");
    };
    getData();
  }

  componentDidUpdate(prevProps){
    if(prevProps.mainListMP !== this.props.mainListMP){
      if(this.props.mainListMP.count < DEFAULT_PAGE_SIZE_MARKET){
        this.setState(state => ({
          ...state,
          showDesktopButton: false
        }))
      }else{
        this.setState(state => ({
          ...state,
          showDesktopButton: true
        }))        
      }
      if (
        this.props.mainListMP.total_pages === this.props.mainListMP.current_page) {
        this.setState((state) => ({
          ...state,
          showDesktopButton: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showDesktopButton: true,
        }));
      }
    }
  }

  componentWillUnmount() {
    setSessionStore("savePlaceLastLocationOpenCardMarket"); // обнуляем в хранилище
    document.documentElement.style.setProperty("--placeholder-size", "12px");
  }

  callback = () => {
    this.setState({
      isLoading: false,
    });
  };

  getOptionsDropDownList = (key, id = null) =>
    this.props.dispatch(ACTION_LIST_OPTIONS_MARKET, {
      key,
      id,
      callback: this.callback,
    });

  handlerChangeFilters = ({ key = null, value = null, id = null }) => {
    let getOptionslist = "";
    if (key === "brand") {
      getOptionslist = "models";
    }
    if (key === "country") {
      getOptionslist = "citys";
    }
    if (key === "model") {
      getOptionslist = "generations";
    }
    if (getOptionslist) {
      this.getOptionsDropDownList(getOptionslist, value);
    } else {
      this.props.dispatch(ACTION_SET_FILTER_INTO_MARKET, {
        key,
        id,
        value,
        callback: this.callback,
      });
    }
  };
  handlerTextSearch = (text, callback) =>
    this.props.dispatch(ACTION_GET_LIST_CARDS_MARKET, { page: 1, callback }); // page: 1 что бы искало с первой страницы

  handlerResetParams = () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    this.props.dispatch(ACTION_RESET_FILTER_INTO_MARKET, {
      callback: this.callback,
    });
  };
  handlerShowHideParams = () => {
    this.setState((state) => ({ ...state, showParams: !state.showParams }));
  };

  callbackPaggination = () => {
    funcDelay(
      () =>
        this.setState((state) => ({
          ...state,
          isLoadingPagination: false,
        })),
      400
    );
  };
  changePagination = ({ page }) => {
    this.setState((state) => ({
      ...state,
      isLoadingPagination: true,
    }));
    this.props.dispatch(ACTION_GET_LIST_CARDS_MARKET, {
      page,
      callback: this.callbackPaggination,
    });
  };

  handleShowMore = () => {
    this.setState((state) => ({
      ...state,
      isLoadingPagination: true,
    }));
    this.props.dispatch(ACTION_SHOW_MORE_LIST_CARDS_MARKET, {
      page: this.props.mainListMP.current_page + 1,
      callback: this.callbackPaggination,
    });
  };

  render() {
    if (this.props.isDesktop) {
      return (
        <CatalogComponentDesktop
          showDesktopButton={this.state.showDesktopButton}
          option={this.props.optionMP}
          handleShowMore={this.handleShowMore}
          showParams={this.state.showParams}
          countFavorite={this.props.favoriteMPCards.count}
          textInputSearch={this.props.textInputMarketSearch}
          listOptionsAuto={this.props.listOptionsMPAuto}
          handlerTextSearch={this.handlerTextSearch}
          handlerResetParams={this.handlerResetParams}
          listOptionsCountry={this.props.listOptionsMPCountry}
          handlerChangeFilters={this.handlerChangeFilters}
          handlerShowHideParams={this.handlerShowHideParams}
          isUpblock={this.props.isUpblock}
          listCards={this.props.mainListMP}
          dispatch={this.props.dispatch}
          textSearch={this.props.textInputMarketSearch}
          isLoading={this.state.isLoading}
          isLoadingPagination={this.state.isLoadingPagination}
          countFilters={this.props.countOptionMPSave}
          isLoadingAuto={this.props.mainListMPIsLoading}
          changePagination={this.changePagination}
          distationtopSearch={this.props.distationtopSearch}
        />
      );
    }
    return (
      <MainCatalogMarketPlace
        isUpblock={this.props.isUpblock}
        listCards={this.props.mainListMP}
        dispatch={this.props.dispatch}
        textSearch={this.props.textInputMarketSearch}
        isLoading={this.state.isLoading}
        isLoadingPagination={this.state.isLoadingPagination}
        countFilters={this.props.countOptionMPSave}
        isLoadingAuto={this.props.mainListMPIsLoading}
        countFavorite={this.props.favoriteMPCards.count}
        changePagination={this.changePagination}
        distationtopSearch={this.props.distationtopSearch}
      />
    );
  }
}

export default connectStoreon(
  "optionMP",
  "mainListMP",
  "favoriteMPCards",
  "countOptionMPSave",
  "listOptionsMPAuto",
  "listOptionsMPCountry",
  "mainListMPIsLoading",
  "textInputMarketSearch",
  WithRouter(MainCatalogMPContainer)
);
