
import React from 'react'
import ModalDesktop from '../../Components/Desktop/ModalDesktop/ModalDesktop';

export default function WrapContainer({children, isDesktop, style={}}) {
  const height = window.innerHeight;
  return (
    <div
      data-wrap="wrap"
      style={{
        position: "relative",
        maxWidth: isDesktop? '' : "var(--max-width-mobile, 320px)",
        overflowY: isDesktop? 'auto' : '',
        width: '100%',
        height: isDesktop? `calc(${height}px - 100px)` : 'auto',
        zIndex: 1,
        borderRadius: 7,
        ...style,
      }}
    >
      {children}
      {isDesktop && <ModalDesktop />}
    </div>
  );
}
