import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Label from "../../../View/Label/Label";
import restrictionLengthText, { getOptions } from "../../../helpers/helpers";
import Offset from "../../../View/Offset";
import TextArea from "../../../View/TextArea/TextArea";
import { addFile, plusFile } from "../../../images";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import FormUploadImageV2 from "../../../View/UploadImage/FormUploadImageV2";
import { respairSchema } from "../../../helpers/schemaValidations/schemaValidate";
import { messageErrorValidation } from "../../../helpers/schemaValidations/messgeSchemaValidations";
import { ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";
import WithTooltip from "../../../HOC/WithTooltip";
import TooltipComponent from "../../../Components/Component.Tooltip/TooltipComponent";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";

const RespairRequest = ({
  tooltip,
  dispatch,
  isDesktop,
  listCountries,
  handlerShowTooltip,
  handleSetDataValues,
}) => {

  return (
    <WrapContainer
      isDesktop={isDesktop}
      style={{
        height: isDesktop ? "max-content" : "auto",
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass={isDesktop ? "profile-desktop__self-info" : ""}>
        {!listCountries.length
        ? <WrapContainerPreloader>
        Загрузка ... <Preloader />
        <Offset mb={30} />
      </WrapContainerPreloader>
        :<Formik
          validationSchema={respairSchema(messageErrorValidation)}
          initialValues={{
            optionsCountries: listCountries,
            optionsCities: [],
            activeCity: false,
            type: "respair",
            country_id: null,
            city_id: null,
            text: "",
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => {            
            useEffect(() => { dispatch(ACTION_SET_CONTROLL_BUTTON, { buttonForm: handleSubmit });  }, [dispatch])
          
            const getDataOptionsRequest = (data) => {
              let options = getOptions(data);
              return options;
            };

            return (
              <Form
                className={isDesktop ? "request-repair-desktop__container" : ""}
              >
                <div
                  className={
                    isDesktop ? "request-repair-desktop__wrap-text" : ""
                  }
                >
                  {/* <Offset mt={10} /> */}
                  <TextArea
                    className={"textarea-application"}
                    value={values.text}
                    placeholder={`Опишите вашу проблему, в чем суть\nполомки и с чем придется работать`}
                    height={90}
                    label={"Ваша проблема"}
                    styleLabel={{
                      fontWeight: 700,
                    }}
                    distationtop={200}
                    id={`textarea-1`}
                    onBlur={handleBlur}
                    name={"text"}
                    style={{
                      border:
                        touched?.text && errors?.text
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    styleWrap={{
                      border:
                        touched?.text && errors?.text
                          ? "1px solid #ff0000"
                          : "1px solid var(--border-select-color)",
                    }}
                    helptext={touched?.text && errors?.text}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const restrict = restrictionLengthText(
                        value,
                        4096,
                        dispatch
                      );
                      if (!restrict) return;
                      setFieldValue("text", value);
                      handleSetDataValues({ 
                        type: 'text',
                        response: value 
                      });
                    }}
                  />
                </div>
                <div
                  className={
                    isDesktop ? "request-repair-desktop__wrap-country" : ""
                  }
                >
                  <Offset mt={17} />

                  <Label style={{ fontWeight: 700 }}>Страна</Label>
                  <Offset mt={9} />
                  <ReactSelectComponent
                    data={getOptions(values.optionsCountries)}
                    enabled={true}
                    isUpTop={isDesktop ? false : true}
                    name={"country_id"}
                    classNamePrefix={"request"}
                    clearValue={!!!values.country_id}
                    placeholder={"Выберите страну"}
                    styleWrap={{
                      border:
                        touched?.country_id && errors?.country_id
                          ? "1px solid var(--text-color-red)"
                          : "",
                    }}
                    helptext={touched?.country_id && errors?.country_id}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(value) => {
                      const sities = getDataOptionsRequest(
                        values.optionsCountries
                          .map((el) => {
                            if (el.id === +value) return el.citys;
                          })
                          .filter((el) => el !== undefined)[0]
                      );
                      setFieldValue("country_id", value);
                      setFieldValue("optionsCities", sities);
                      setFieldValue("city_id", null);
                      handleSetDataValues({ 
                        type: 'country_id',
                        response: value 
                      });
                    }}
                  />
                </div>

                <div
                  className={
                    isDesktop ? "request-repair-desktop__wrap-city" : ""
                  }
                >
                  <Offset mt={18} />
                  <Label style={{ fontWeight: 700 }}>Ваш город</Label>
                  <Offset mt={8} />
                  <TooltipComponent
                    onClick={(e) =>
                      !!!values.optionsCities.length &&
                      handlerShowTooltip({
                        key: "request",
                        action: "city",
                        e,
                      })
                    }
                    style={{ bottom: -10, left: -10 }}
                    message={tooltip?.request?.city?.message}
                    isShow={
                      tooltip?.request?.city && tooltip?.request?.city?.isShow
                    }
                  >
                    <ReactSelectComponent
                      data={values.optionsCities}
                      enabled={!!values.country_id}
                      isUpTop={isDesktop ? false : true}
                      name={"city"}
                      classNamePrefix={"request"}
                      clearValue={!!!values.city}
                      placeholder={"Выберите город"}
                      minMenuHeight={150}
                      maxMenuHeight={150}
                      onChange={(value) => {
                        setFieldValue("city_id", value);
                        handleSetDataValues({ type: 'city_id', response: value });
                      }}
                      styleWrap={{
                        border:
                          touched?.city_id && errors?.city_id
                            ? "1px solid var(--text-color-red)"
                            : "",
                      }}
                      helptext={touched?.city_id && errors?.city_id}
                      stylehelptext={{
                        color: "var(--text-color-red)",
                      }}
                    />
                  </TooltipComponent>
                </div>
                <FormUploadImageV2
                  multiple
                  className={"request-repair-desktop__wrap-upload-images"}
                  image={addFile}
                  listImages={
                    isDesktop ? [{ url: plusFile, type: "fake-image" }] : []
                  }
                  values={values}
                  maxCountImage={8}
                  maxSizeImage={5242880} // 5242880 => 5mb
                  setFieldValue={setFieldValue}
                  uploadTypeName="image"
                  positionPreview={isDesktop ? "over" : "under"}
                  onChange={({ key, value, callback }) => {
                    handleSetDataValues({ type: key,
                      response: value 
                    });
                  }}
                />
                <Offset mt={values?.image?.length? 38 : 18} />
              </Form>
            );
          }}
        </Formik>
}
        <Offset mt={37} />
        {isDesktop && <Offset mt={30} />}
        {isDesktop && <ButtonApplyContainer isDesktop={isDesktop} />}
      </BlockGrid>
    </WrapContainer>
  );
};
export default (WithTooltip(RespairRequest));
