import React, { Component } from 'react'
import ArrowCameback from './ArrowCameback'
import WithRouter from '../../../HOC/WithRouter'

export class ArrowCamebackContainer extends Component {
    handlerClickCameback = () => {
      this.props.navigate(-1)
    }
  render() {
    return (
      <ArrowCameback 
        handlerClickCameback={this.handlerClickCameback}
      />
    )
  }
}

export default WithRouter( ArrowCamebackContainer)