import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../../HOC/WithRouter';
import SearchCarDisassembled from './SearchCarDisassembled';
import { MENU_CAR_DISASSEMBLED } from '../../../helpers/config';
import { GET_DATA_CAR_BY_TYPE, RESET_DATA_FIND_CAR_DISASSEMBLED, RESET_LIST_DATA_FIND_CAR_DISASSEMBLED, SET_DATA_FIND_CAR_DISASSEMBLED } from '../../../store/car-disassembled/carDisassebled';


class SearchCarDisassembledContainer extends Component {

  componentDidMount() {
      this.props.dispatch(GET_DATA_CAR_BY_TYPE, { type: 'brand', callback: () => console.log('brand') });
      this.props.controllerHeaderBand({
        currentTextHandlerBand: 'Найти авто в разборе',
        pathBackButton: () => this.handleChangeScreen({ path: MENU_CAR_DISASSEMBLED })
      });
    }
    handleChangeScreen = ({ path }) => {
      this.props.navigate(path)
    }
  componentWillUnmount(){
    this.props.dispatch(RESET_DATA_FIND_CAR_DISASSEMBLED)
    this.props.dispatch(RESET_LIST_DATA_FIND_CAR_DISASSEMBLED)
  }

  handleSetDataValues = values => this.props.dispatch(SET_DATA_FIND_CAR_DISASSEMBLED, { ...values })
  
    handleGetDataType = ({type, setFieldValue, ...values}) => this.props.dispatch(GET_DATA_CAR_BY_TYPE, {
      type, 
      callback: (res)=>{
        setFieldValue([type], res)
      },
      ...values,
    })

  render() {
    // if(this.props.isDesktop){
    //   return (
    //     <SearchCarDisassembledDesktop
    //       isDesktop={this.props.isDesktop}
    //       dispatch={this.props.dispatch}
    //       handleSetDataValues={this.handleSetDataValues}
    //     />
    //   );
    // }
    return (
      <SearchCarDisassembled
        isDesktop={this.props?.isDesktop}
        dispatch={this.props.dispatch}
        listBrands={this.props.listCarDataByType['brand']}
        listModel={this.props.listCarDataByType['model']}
        listGeneration={this.props.listCarDataByType['generation']}
        listFindedCarDisassembled = {this.props.listFindedCarDisassembled}
        handleGetDataType={this.handleGetDataType}
        handleSetDataValues={this.handleSetDataValues}
      /> 
    )
  }
}

export default connectStoreon( 
  'listCarDataByType',
  'listFindedCarDisassembled',
  WithRouter(SearchCarDisassembledContainer)
);