import React from "react";
import Offset from "../../View/Offset";

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    window.onerror = (message, source, lineno, colno) => {
      if (typeof message === "string") {
        if (message.includes("ChunkLoadError")) {
          return alert(
            "Проблемы с подключением к серверу. \n Проверьте соединение с интернетом и попробуйте снова"
          );
        }
      }
    };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // logErrorToMyService(error, errorInfo);
    console.error("Ошибка в приложении:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (
        <Offset mt={150} mb={150}>
          <h1>Что-то пошло не так.</h1>
        </Offset>
      );
    }

    return this.props.children;
  }
}

export default ErrorHandler;
