import React, { Component } from "react";
import { getLocaleStore,} from "../../../helpers/utils";
import WithRouter from "../../../HOC/WithRouter";
import { connectStoreon } from "storeon/react";
import TransportRequest from "../TransportRequest/TransportRequest";
import TruckRequest from "../TruckRequest/TruckRequest";
import {
  ACTION_SET_VALUE_UNIT_SPARE,
  DELETE_AUTOPART_FROM_LIST_UNIT_SPARE,
  GET_APPlICATION_FROM_TYPE_ID,
  GET_LIST_OPTIONS_BY_TYPE,
} from "../../../store/requests/requests";
import UnitSpareRequestEdit from "./UnitSpareRequestEdit";

class OwnRequestEditComponent extends Component {
  state = {
    type: "edit_",
    isShowHide: true,
    isAddToListSparePart: false,
  };

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Редактирование запроса",
      pathBackButton: getLocaleStore("lastPathOwnRequest"),
    });

    this.props.dispatch(GET_LIST_OPTIONS_BY_TYPE, {
      type: "autoparts",
      screen: this.state.type,
      callback: () =>
        this.props.dispatch(GET_APPlICATION_FROM_TYPE_ID, {
          type: getLocaleStore("itemTabRequest"),
          id: getLocaleStore("itemIdOwnRequest"),
        }),
    });
  }

  handleDeleteAutoPartFromList = (id) =>
      this.props.dispatch(DELETE_AUTOPART_FROM_LIST_UNIT_SPARE, { 
        id,
        type: getLocaleStore("itemTabRequest"),
        screen: this.state.type + getLocaleStore("itemTabRequest"),
       });

  handleSparePart = () => {
    this.setState((state) => ({
      ...state,
      isAddToListSparePart: !state.isAddToListSparePart,
    }));
  };

  saveValueStore = (obj) =>
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      ...obj,
      screen: this.state.type + getLocaleStore("itemTabRequest"),
      callback: this.callback,
      edit: true,
    });

  handleGetDataOption = (obj) =>
    this.props.dispatch(GET_LIST_OPTIONS_BY_TYPE, {
      ...obj,
      screen: this.state.type + getLocaleStore("itemTabRequest"),
    });

  handlerDeleteImage = (data) => {
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      type: "delete_request_images",
      edit: true,
      screen: this.state.type + getLocaleStore("itemTabRequest"),
      response: [...this.props.valuesUnitSpare.delete_request_images, data.id],
      callback: data.callback,
    });
  };

  render() {
    if (
      getLocaleStore("itemTabRequest") === "unit" ||
      getLocaleStore("itemTabRequest") === "spare"
    ) {
      return (
        <UnitSpareRequestEdit
          edit
          dispatch={this.props.dispatch}
          isDesktop={this.props.isDesktop}
          handlerDeleteImage={this.handlerDeleteImage}
          saveValueStore={this.saveValueStore}
          valuesUnitSpare={this.props.valuesUnitSpare}
          isAddToListSparePart={this.state.isAddToListSparePart}
          handleSparePart={this.handleSparePart}
          handleGetDataOption={this.handleGetDataOption}
          handleDeleteAutoPartFromList={this.handleDeleteAutoPartFromList}
        />
      );
    }
    if (getLocaleStore("itemTabRequest") === "transport") {
      return (
        <TransportRequest
          edit
          isDesktop={this.props.isDesktop}
          dispatch={this.props.dispatch}
          saveValueStore={this.saveValueStore}
          valuesUnitSpare={this.props.valuesUnitSpare}
        />
      );
    }
    if (getLocaleStore("itemTabRequest") === "truck") {
      return (
        <TruckRequest
          edit
          isDesktop={this.props.isDesktop}
          dispatch={this.props.dispatch}
          saveValueStore={this.saveValueStore}
          valuesUnitSpare={this.props.valuesUnitSpare}
        />
      );
    }
    return <div>OwnRequestEditComponent</div>;
  }
}
export default connectStoreon(
  "access",
  "infoEngine",
  "listBrands",
  "pageMyApplication",
  "listMyApplication",
  "valuesUnitSpare",
  WithRouter(OwnRequestEditComponent)
);
