import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../../HOC/WithRouter';
import CarDisassembled from './CarDisassembled';
import { MENU_CAR_DISASSEMBLED, ROOT } from '../../../helpers/config';
import CarDisassembledDesktop from '../../../PagesDesktop/CarDisassembledDesktop/CreateCarDisassembledDesktop/CreateCarDisassembledDesktop';
import { GET_DATA_CAR_BY_TYPE, SET_DATA_CAR_DISASSEMBLED } from '../../../store/car-disassembled/carDisassebled';

class CarDisassembledContainer extends Component {

  componentDidMount() {
    this.props.dispatch(GET_DATA_CAR_BY_TYPE, { type: 'brand', callback: () => console.log('brand') });
    this.props.controllerHeaderBand({
      currentTextHandlerBand: 'Создать авто в разборе',
      pathBackButton: () => this.handleChangeScreen({ path: MENU_CAR_DISASSEMBLED })
    });
  }
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path)
  }
  
  handleSetDataValues = values => this.props.dispatch(SET_DATA_CAR_DISASSEMBLED, { ...values });

  handleGetDataType = ({type, setFieldValue, ...values}) => this.props.dispatch(GET_DATA_CAR_BY_TYPE, {
    type, 
    callback: (res)=>{
      setFieldValue([type], res)
    },
    ...values,
  })
  
  render() {
    if(this.props.isDesktop){
      return (
        <CarDisassembledDesktop
          isDesktop
          dispatch={this.props.dispatch}
          listBrands= {this.props.listCarDataByType['brand']}
          listModel= {this.props.listCarDataByType['model']}
          listGeneration= {this.props.listCarDataByType['generation']}
          handleGetDataType={this.handleGetDataType}
          handleSetDataValues={this.handleSetDataValues}
        />
      );
    }
    return (
      <CarDisassembled
        dispatch={this.props.dispatch}
        listBrands= {this.props.listCarDataByType['brand']}
        listModel= {this.props.listCarDataByType['model']}
        listGeneration= {this.props.listCarDataByType['generation']}
        handleGetDataType={this.handleGetDataType}
        handleSetDataValues={this.handleSetDataValues}
    /> 
    )
  }
}

export default connectStoreon( 
  'listCarDataByType',
  WithRouter(CarDisassembledContainer)
);