import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react';
import MenuCarDisassembled from './MenuCarDisassembled';
import { subMenuSellCar } from '../../helpers/config';
import WithRouter from '../../HOC/WithRouter';

class MenuCarDisassembledContainer extends Component {

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: 'Авто в разборе',
      pathBackButton: () => this.handleChangeScreen({ path: ROOT })
    });
  }

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  }
  render() {
    // if(this.props.isDesktop){
    //   return (
    //     <CarDisassembledDesktop
    //       dispatch={this.props.dispatch}
    //       handleSetDataValues={this.handleSetDataValues}
    //     />
    //   );
    // }
    return (
      <MenuCarDisassembled
        isDesktop={this.props.isDesktop}
        listMenu={subMenuSellCar}
        handleChangeScreen={this.handleChangeScreen}
    /> 
    )
  }
}

export default connectStoreon( 
  WithRouter(MenuCarDisassembledContainer)
);