import React, { Component } from 'react'
import Requests from './Requests'
import { ACTION_SET_BUTTON_HEADER_ACTION, ACTION_SET_CONTROLL_BUTTON } from '../../store/helpers/helpers-store';
import { MAKE_REQUEST_MENU, MAKE_REQUEST_OWN_REQUEST, MAKE_REQUEST_SPARE, MAKE_REQUEST_UNIT, ROOT } from '../../helpers/config';
import { myRequest } from '../../images';
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../HOC/WithRouter';

export class RequestsComponentDesktop extends Component {
  state = {
    menu: [
      {
        id: 0,
        name: "Запрос на агрегат",
        isActive: false,
      },
      {
        id: 1,
        name: "Запрос на запчасть",
        isActive: false,
      },
      {
        id: 2,
        name: "Запрос на ремонт",
        isActive: false,
      },
      {
        id: 3,
        name: "Запрос на грузовые",
        isActive: false,
      },
      {
        id: 4,
        name: "Грузоперевозки",
        isActive: false,
      },
    ],
  };

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "СОЗДАТЬ ЗАПРОС",
      pathBackButton: () => this.handleChangeScreen({ path: ROOT }), //ROOT,
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: MAKE_REQUEST_OWN_REQUEST }),
          iconLeft: myRequest,
          styleIconsLeft: { height: 12 },
          title: "Мои запросы",
          className: "button__controll--roze",
        },
      ],
    });    
      console.log('request menu')
      if (MAKE_REQUEST_SPARE === window.location.pathname) {
        this.setState(prevState =>({
          menu: prevState.menu.map((item) =>
            item.id === 1
             ? {...item, isActive: true }
              : {...item, isActive: false }
          )
        }))
      } else if (MAKE_REQUEST_UNIT === window.location.pathname) {
        this.setState(prevState =>({
          menu: prevState.menu.map((item) =>
            item.id === 0
             ? {...item, isActive: true }
              : {...item, isActive: false }
          )
        }))
      } 
  }

  // componentDidUpdate(prevProps, prevState){
  //   if(prevProps.pathname !== this.props.pathname && ){
  //     console.log('other pathname')
  //   }
  //   console.log({prevProps, prevState})
  // }

  handlerChangeItemMenu = (itemId) => {
    this.setState((prevState) => ({
      menu: prevState.menu.map((item) =>
        item.id === itemId
          ? { ...item, isActive: !item.isActive }
          : { ...item, isActive: false }
      ),
    }));
  };

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };
  render() {
    return (
      <Requests
        listMenu={this.state.menu}
        handlerChangeItemMenu={this.handlerChangeItemMenu}
      />
    );
  }
}

export default connectStoreon(
  'valueUnitSpare',
  WithRouter(RequestsComponentDesktop));