import { compare } from "../helpers/const";
import { isAndroid, isIos } from "../helpers/utils";

const WithTelegram = ({ children }) => {
  const Telegram = window.Telegram;
  const checkVersion = (callback) => {
    const needVersion = "8.0";
    if (compare(Telegram.WebApp.version, needVersion)) {
      callback();
    } else {
    }
  };
  if (!Telegram) {
    throw new Error("Telegram is not available in this context");
  }
  if (!isIos() && !isAndroid()) {
      checkVersion(() => Telegram.WebApp?.requestFullscreen());
  }else{
    Telegram.WebApp?.disableVerticalSwipes();
    if (!Telegram.WebApp.isExpanded) Telegram.WebApp.expand();
  }
  return children;
};

export default WithTelegram;
