
import { v4 } from "uuid";
import { _INIT, ACTION_DELETE, ACTION_GET, ACTION_POST } from "../api-store/getpage";
import { API_CREATE_CAR_DISASSEMBLE, API_GET_DATA_CAR, API_GET_LIST_CAR_DISASSEMBLED, API_GET_LIST_MY_CAR_DISASSEMBLED, MENU_CAR_DISASSEMBLED, ROOT } from "../../helpers/config";
import { activeButtonBootomForConfirm, handlerWarningInfoMessageResponse } from "../../helpers/helpers";
import { ACTION_OPEN_MODAL, ACTION_SET_CONTROLL_BUTTON, ACTION_SET_CONTROLL_BUTTON_NULL } from "../helpers/helpers-store";
import { docSuccess } from "../../images";

export const SET_CAR_DISASSEMBLE = v4();
export const SET_CAR_DISASSEMBLE_NULL = v4();
export const GET_DATA_CAR_BY_TYPE = v4();
export const SET_DATA_CAR_DISASSEMBLED = v4();
export const SET_DATA_CAR_DISASSEMBLED_NULL = v4();
export const SEND_DATA_CAR_DISASSEMBLED = v4();

export const SET_DATA_FIND_CAR_DISASSEMBLED = v4();
export const SET_LIST_FIND_CAR_DISASSEMBLED = v4();
export const RESET_DATA_FIND_CAR_DISASSEMBLED = v4();
export const RESET_LIST_DATA_FIND_CAR_DISASSEMBLED = v4();

export const GET_LIST_MY_APPLICATION_CAR_DISASSEMBLED = v4();
export const SET_LIST_MY_APPLICATION_CAR_DISASSEMBLED = v4();
export const RESET_LIST_MY_APPLICATION_CAR_DISASSEMBLED = v4();
export const DELETE_MY_APPLICATION_CAR_DISASSEMBLED = v4();

export const carDisassebled = store => {
    // Init state
    const initStateCarDisassembled = {
        brand: [],
        model: [],
        generation: [],
    };
    const initStateValues = {
        brand: '',
        model: '',
        generation: '',
        text: '',
        image: [],
    };
    const initFindDisassembledCar = {
        brand_id: '',
        model_id: '',
        generation_id: '',
    };
    const initListFindedDisassembledCar = {
        count: 0,
        results: [],
    }
    const initListMyApplicationDisassembledCar = {
        count: 0,
        results: [],
    }
    store.on(_INIT, () => ({ myApplicationCarDisassembledValues: initListMyApplicationDisassembledCar }));
    store.on(RESET_LIST_MY_APPLICATION_CAR_DISASSEMBLED, () => ({ myApplicationCarDisassembledValues: initListMyApplicationDisassembledCar }));
    store.on(SET_LIST_MY_APPLICATION_CAR_DISASSEMBLED, (_, payload) => ({ myApplicationCarDisassembledValues: payload }));
    store.on(GET_LIST_MY_APPLICATION_CAR_DISASSEMBLED, ({ }, payload, { dispatch }) => { 
        const url = API_GET_LIST_MY_CAR_DISASSEMBLED;
        let params = {
            dataRequst: res => {

                if(typeof payload?.callback === 'function') payload.callback(res);
                dispatch(SET_LIST_MY_APPLICATION_CAR_DISASSEMBLED, res);
            },
            url,
            ...payload
        };
            
        dispatch(ACTION_GET, params);
    });
    
    store.on(DELETE_MY_APPLICATION_CAR_DISASSEMBLED, ({ }, payload, { dispatch }) => { 
        const url = API_CREATE_CAR_DISASSEMBLE;
        let params = {
            dataRequst: res => {
                const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
                if (isWarning) return;
                if(typeof payload?.callback === 'function') payload.callback(res);
                dispatch(SET_LIST_MY_APPLICATION_CAR_DISASSEMBLED, res);
                setTimeout(() => dispatch(GET_LIST_MY_APPLICATION_CAR_DISASSEMBLED),1500)
            },
            url,
            ...payload
        };
            
        dispatch(ACTION_DELETE, params);
    });

    store.on(_INIT, () => ({ findCarDisassembledValues: initFindDisassembledCar }));
    store.on(RESET_DATA_FIND_CAR_DISASSEMBLED, () => ({ findCarDisassembledValues: initFindDisassembledCar }));
    store.on(_INIT, () => ({ listFindedCarDisassembled: initListFindedDisassembledCar }));
    store.on(RESET_LIST_DATA_FIND_CAR_DISASSEMBLED, () => ({ listFindedCarDisassembled: initListFindedDisassembledCar }));
    store.on(SET_LIST_FIND_CAR_DISASSEMBLED, ({  }, payload) => ({ listFindedCarDisassembled : payload}));
    store.on(SET_DATA_FIND_CAR_DISASSEMBLED, ({ findCarDisassembledValues }, payload, { dispatch }) => {
       let newData = {
           ...findCarDisassembledValues,
            ...payload
        }
        if(newData.brand_id && newData.model_id && newData.generation_id){
            const url = API_GET_LIST_CAR_DISASSEMBLED;
            let params = {
                page_size: 999,
                dataRequst: res => {
                    console.log({res})
                    const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
                    if (isWarning) return;
                    if (typeof payload?.callback === 'function') payload.callback();
                    dispatch(SET_LIST_FIND_CAR_DISASSEMBLED, res);
                },
                url,
                ...newData
            };
            dispatch(ACTION_GET, params);
        }else{
            dispatch(RESET_LIST_DATA_FIND_CAR_DISASSEMBLED)
        }
        console.log(newData)
        return newData;
    });

    store.on(SET_CAR_DISASSEMBLE, (_, payload) => ({ listCarDataByType: payload }));

    store.on(_INIT, () => ({ carDisassembledValues: initStateValues }));
    store.on(SET_DATA_CAR_DISASSEMBLED_NULL, () => ({ carDisassembledValues: initStateValues }));
    store.on(_INIT, () => ({ listCarDataByType: initStateCarDisassembled }));
    store.on(SET_CAR_DISASSEMBLE, (_, payload) => ({ listCarDataByType: payload }));
    store.on(SET_CAR_DISASSEMBLE_NULL, () => ({ listCarDataByType: initStateCarDisassembled }));
    store.on(GET_DATA_CAR_BY_TYPE, ({ listCarDataByType }, payload, { dispatch }) => { 
        const { type } = payload;
        const url = API_GET_DATA_CAR;
        let params = {
            type,
            dataRequst: res => {
            
                dispatch(SET_CAR_DISASSEMBLE, {
                    ...listCarDataByType,
                    [type]: res,
                });

            if(typeof payload?.callback === 'function') payload.callback(res);
            },
            url,
            ...payload
        };
            
        dispatch(ACTION_GET, params);
    });
    
    store.on(SEND_DATA_CAR_DISASSEMBLED, (_, payload, { dispatch }) => { 
        
        const url = API_CREATE_CAR_DISASSEMBLE;
        let params = {
            dataRequst: res => {
                const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
                if (isWarning) return;
                if (typeof payload?.callback === 'function') payload.callback();
                
                dispatch(ACTION_OPEN_MODAL, {
                    show: res?.info?.status,
                    content: res?.info?.message,
                    contentBtn: 'Ок',
                    actionClickOk: () => {
                        dispatch(SET_DATA_CAR_DISASSEMBLED_NULL)
                        dispatch(SET_CAR_DISASSEMBLE_NULL)
                    },
                    error: !res?.info?.status,
                    path: MENU_CAR_DISASSEMBLED,
                    icon: docSuccess
                })
            },
            url,
            ...payload
        };
        dispatch(ACTION_POST, params);
    });
    
    store.on(SET_DATA_CAR_DISASSEMBLED, ({ carDisassembledValues }, payload, { dispatch }) => { 
        let newValues = {
            ...carDisassembledValues,
            ...payload
        }
        console.log({ newValues })

        const isActiveButton = activeButtonBootomForConfirm({ ...newValues }, 'car-disassembled');
        if (isActiveButton) {
        dispatch(ACTION_SET_CONTROLL_BUTTON, {
            isActive: true,
            name: 'Создать',
            action: () => {
                dispatch(SEND_DATA_CAR_DISASSEMBLED, { ...newValues })
                
            return dispatch(ACTION_SET_CONTROLL_BUTTON_NULL)
            }
        })
        }else{
        dispatch(ACTION_SET_CONTROLL_BUTTON, {
            isActive: false,
        })
        }
        return ({ carDisassembledValues: {...newValues}})
    });

}