import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../../HOC/WithRouter';
import DeleteMyApplicationCarDisassembled from './DeleteMyApplicationCarDisassembled';
import { MENU_CAR_DISASSEMBLED } from '../../../helpers/config';
import { DELETE_MY_APPLICATION_CAR_DISASSEMBLED, GET_LIST_MY_APPLICATION_CAR_DISASSEMBLED, RESET_LIST_MY_APPLICATION_CAR_DISASSEMBLED } from '../../../store/car-disassembled/carDisassebled';


class DeleteMyApplicationCarDisassembledContainer extends Component {

  componentDidMount() {
      this.props.dispatch(GET_LIST_MY_APPLICATION_CAR_DISASSEMBLED);
      this.props.controllerHeaderBand({
        currentTextHandlerBand: 'Мои заявки авто в разборе',
        pathBackButton: () => this.handleChangeScreen({ path: MENU_CAR_DISASSEMBLED })
      });
    }
    handleChangeScreen = ({ path }) => {
      this.props.navigate(path)
    }
  componentWillUnmount(){
    this.props.dispatch(RESET_LIST_MY_APPLICATION_CAR_DISASSEMBLED)   
  }

  handleDeleteApplication = (id) => {
    this.props.dispatch(DELETE_MY_APPLICATION_CAR_DISASSEMBLED, {
      parsing_id: id,
    })
  }

  render() {
    return (
      <DeleteMyApplicationCarDisassembled
        isDesktop={this.props?.isDesktop}
        dispatch={this.props.dispatch}
        listApplications={this.props.myApplicationCarDisassembledValues}
        handleDeleteApplication={this.handleDeleteApplication}
      /> 
    )
  }
}

export default connectStoreon( 
  'myApplicationCarDisassembledValues',
  WithRouter(DeleteMyApplicationCarDisassembledContainer)
);