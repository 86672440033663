import React, { useEffect } from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import Label from "../../../View/Label/Label";
import FormUploadImageV2 from "../../../View/UploadImage/FormUploadImageV2";
import { addFile, plusFile } from "../../../images";
import TextArea from "../../../View/TextArea/TextArea";
import Form from "../../../View/Form/Form";
import { ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";
import { Formik } from "formik";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";
import restrictionLengthText from "../../../helpers/helpers";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";

const CreateCarDisassembledDesktop = ({ 
  dispatch, 
  isDesktop,
  listBrands,
  listModel,
  listGeneration,
  handleGetDataType,
  handleSetDataValues, 
 }) => {
   if(!!!listBrands.length) {
   return <WrapContainerPreloader>
     Загрузка... <Preloader /> <Offset mb={30} />{" "}
   </WrapContainerPreloader>
 }
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />


        <Formik
          initialValues={{
            brand: [...listBrands],
            model: [...listModel],
            generation: [...listGeneration],
            brand_id: '',
            model_id: '',
            generation_id: '',
            text: "",
            image: [],
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => {
            useEffect(() => { dispatch(ACTION_SET_CONTROLL_BUTTON, { buttonForm: handleSubmit });  }, [dispatch])
            
            return (
              <Form 
                style={{
                  display: 'grid',
                  gap: 15,
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                  gridTemplateAreas: `'brand brand model model generation generation'
                                      'text text text image image image'
                                      `,
                }}      
              >
                <ReactSelectComponent
                  label={"Марка *"}
                  data={values.brand}
                  enabled={true}
                  isUpTop={false}
                  name={"brand"}
                  classNamePrefix={"request"}
                  // clearValue={!!!values.power}
                  placeholder={"Выберите марку"}
                  onChange={(value) => {
                    setFieldValue("brand_id", value);
                    setFieldValue("model_id", '');
                    setFieldValue("generatin_id", '');
                    handleGetDataType({
                      type: 'model',
                      setFieldValue,
                      brand_id: value,
                    });
                    handleSetDataValues({
                      'brand_id': value,
                      'model_id': '',
                      'generation_id': '',
                    })
                  }}
                  styleWrap={{
                    gridArea: 'brand',
                  }}
                />
                <ReactSelectComponent
                  label={"Модуль *"}
                  data={values.model}
                  enabled={values.brand_id}
                  isUpTop={false}
                  name={"modal"}
                  classNamePrefix={"request"}
                  clearValue={values.brand_id}
                  placeholder={"Выберите модель"}
                  onChange={(value, action) => {
                    console.log({ value })
                    setFieldValue("model_id", value);
                    setFieldValue("generatin_id", '');
                    handleGetDataType({
                      type: 'generation',
                      setFieldValue,
                      brand_id: values.brand_id,
                      model_id: value,
                    });
                    handleSetDataValues({
                      'brand_id': values.brand_id,
                      'model_id': value,
                      'generation_id': '',
                    })
                  }}
                  styleWrap={{
                    gridArea: 'model',
                  }}
                />
                <ReactSelectComponent
                  label={"Поколение *"}
                  data={values.generation}
                  enabled={values.model_id}
                  isUpTop={false}
                  name={"generation"}
                  classNamePrefix={"request"}
                  clearValue={values.model_id || values.brand_id}
                  placeholder={"Выберите поколение"}
                  onChange={(value, action) => {
                    setFieldValue("generation_id", value);
                    handleSetDataValues({
                      'brand_id': values.brand_id,
                      'model_id': values.model_id,
                      'generation_id': value,
                    })
                  }}
                  styleWrap={{
                    gridArea: 'generation',
                  }}
                />

                <div style={{gridArea: 'text'}}>
                  <TextArea
                    label={'Текст объявления'}
                    className={"textarea-application"}
                    value={values.text}
                    placeholder={`Опишите объявление`}
                    height={90}
                    distationtop={290}
                    id={`textarea-1`}
                    name={"text"}
                    onBlur={handleBlur}
                    helptext={touched?.text && errors?.text}
                    style={{
                      border:
                        touched?.text && errors?.text ? "1px solid #ff0000" : "",
                    }}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const restrict = restrictionLengthText(
                        value,
                        4096,
                        dispatch
                      );
                      if (!restrict) return;
                      setFieldValue("text", value);
                      handleSetDataValues({
                        'brand_id': values.brand_id,
                        'model_id': values.model_id,
                        'generation_id': values.generation_id,
                        'text': value,
                      })
                    }}
                   
                  />
                </div>
                <FormUploadImageV2
                  title="Прикрепить изображение **"
                  className={'image_add'}
                  listImages={
                    isDesktop
                      ? [
                        { url: plusFile, type: "fake-image" },
                        ...values.image,
                      ]
                      : [...values.image]
                  }
                  multiple
                  image={addFile}
                  values={values}
                  setFieldValue={setFieldValue}
                  maxCountImage={8}
                  maxSizeImage={5242880} // 5242880 => 5mb
                  uploadTypeName="image"
                  positionPreview={"over"}
                  onChange={({ key, value, callback }) => {
                    handleSetDataValues({
                      'brand_id': values.brand_id,
                      'model_id': values.model_id,
                      'generation_id': values.generation_id,
                      'text': values.text,
                      [key]: value,
                    })
                  }}
                />
                {/* <Offset mt={values?.image?.length ? 38 : 18} /> */}
              </Form>
            );
          }}
        </Formik>
        <Offset mt={85} />
          <Label style={{ fontWeight: 200, opacity: .5, gridArea: "info_1", }}>* - обязательные поля для заполнения</Label>
          <Label style={{ fontWeight: 200, opacity: .5, gridArea: "info_2", }}>** - Картинка лучше тысячи слов! Прикрепите изображение, если это возможно.</Label>
        <Offset mt={15} />
        <BlockGrid>
          <ButtonApplyContainer isDesktop />
        </BlockGrid>
    </WrapContainer>
  );
};

export default CreateCarDisassembledDesktop;
